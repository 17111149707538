import {
  BillInput,
  useGetBillQuery,
  UserFragment,
  useUpdateBillMutation,
  useUpdateDocumentMutation,
} from "../../generated/graphql";
import { Alert, Button, Container } from "@mui/material";
import BillForm from "./BillForm";
import CircularProgress from "@mui/material/CircularProgress";
import CheckIcon from "@mui/icons-material/Check";
import React from "react";

const UpdateBillForm = ({
  user,
  editBillId,
  setUpdateBill,
  userRefetch,
  disableAddDocument,
  addDocumentToBill,
}: {
  user: UserFragment;
  editBillId: string;
  setUpdateBill?: (billId: string) => void;
  userRefetch: () => void;
  documentId?: string;
  disableAddDocument: boolean;
  addDocumentToBill?: () => void;
}) => {
  const { data, loading, error } = useGetBillQuery({
    variables: {
      billId: editBillId,
      userId: user.id,
    },
  });
  const [updateBillMutation, updateBillResult] = useUpdateBillMutation();
  const [updateDocumentMutation, updateDocumentResult] =
    useUpdateDocumentMutation();

  const onSave = (billInput: BillInput) => {
    const promise = updateBillMutation({
      variables: {
        billId: editBillId,
        updatedBill: billInput,
        userId: user.id,
      },
    });
    return promise;
  };

  if (loading || !data) {
    return <div>Loading...</div>;
  }

  return (
    <Container>
      <BillForm
        isBillUpdate={true}
        user={user as UserFragment}
        userRefetch={userRefetch}
        formData={data.getBill}
        onSave={onSave}
        loading={loading}
        error={error?.message}
        setUpdateBill={setUpdateBill}
      />
      {addDocumentToBill && (
        <Button
          onClick={addDocumentToBill}
          variant="outlined"
          style={{ margin: "20px", width: "90%" }}
          disabled={disableAddDocument}
        >
          {updateDocumentResult.loading && <CircularProgress size="20px" />}
          Add Document To Bill
        </Button>
      )}
      {updateBillResult.called && !updateBillResult.error && (
        <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
          The bill has been saved
        </Alert>
      )}
      {updateBillResult.error && (
        <Alert icon={<CheckIcon fontSize="inherit" />} severity="error">
          Error saving bill: {updateBillResult.error.message}
        </Alert>
      )}
      {updateDocumentResult.called && !updateDocumentResult.error && (
        <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
          The document has been saved to the bill.
        </Alert>
      )}
      {updateDocumentResult.error && (
        <Alert icon={<CheckIcon fontSize="inherit" />} severity="error">
          Error saving document to bill: {updateDocumentResult.error.message}
        </Alert>
      )}
    </Container>
  );
};

export default UpdateBillForm;
