import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  useGetDocumentQuery,
  useGetUserQuery,
  useCreateBillMutation,
  useUpdateDocumentMutation,
  UserFragment,
  BillInput,
} from "../generated/graphql";
import InspectImageList from "../components/InspectImageList";
import { Container, Stack, Alert } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import BillForm from "../components/form/BillForm";
import UpdateBillForm from "../components/form/UpdateBillForm";

function DocumentToBill() {
  const userId = useParams().userId || "test";
  const documentId = useParams().documentId || "test";
  const [formData, setFormData] = useState(null);
  const [editBillId, setEditBillId] = useState<string | null>(null);
  const [saving, setSaving] = useState(false);
  const [complete, setComplete] = useState(false);

  const {
    data,
    loading,
    error,
    refetch: refetchDocument,
  } = useGetDocumentQuery({
    variables: {
      userId,
      documentId,
    },
  });

  const {
    data: userData,
    loading: userDataLoading,
    error: userDataError,
    refetch,
  } = useGetUserQuery({
    variables: {
      userId: userId,
    },
  });

  const [createBillMutation, createBillResult] = useCreateBillMutation();
  const [updateDocumentMutation, updateDocumentResult] =
    useUpdateDocumentMutation();

  const onSave = (billInput: BillInput) => {
    setSaving(true);
    createBillMutation({
      variables: {
        bill: billInput,
        userId,
      },
    });
  };

  const addDocumentToBill = () => {
    return updateDocumentMutation({
      variables: {
        userId,
        documentId: documentId || "test",
        document: {
          billId: editBillId,
        },
      },
    });
  };

  useEffect(() => {
    if (createBillResult.called) {
      updateDocumentMutation({
        variables: {
          userId,
          documentId,
          document: {
            billId: createBillResult.data?.createBill,
          },
        },
      });
    }
  }, [createBillResult.called]);

  useEffect(() => {
    if (updateDocumentResult.called) {
      refetchDocument();
      setFormData(null);
      setSaving(false);
      setComplete(true);
    }
  }, [updateDocumentResult.called, refetchDocument]);

  if (complete) {
    return <div>Bill Saved!</div>;
  }

  if (loading || userDataLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error loading document: {error.message}</div>;
  }
  if (userDataError) {
    return <div>Error loading user: {userDataError.message}</div>;
  }

  const document = data?.getDocument;
  const user = userData?.getUser;

  if (!document) {
    return (
      <div>
        No Document found for ID {documentId} and userId {userId}
      </div>
    );
  }

  return (
    <Stack direction={"row"} margin={2} style={{ width: "98%" }}>
      <Container style={{ width: "50%" }}>
        <InspectImageList images={document?.imageUrls as string[]} />
      </Container>
      <Container style={{ width: "50%" }}>
        {!editBillId && (
          <BillForm
            isBillUpdate={false}
            user={user as UserFragment}
            userRefetch={refetch}
            formData={formData}
            onSave={onSave}
            loading={false}
            error={createBillResult?.error?.message}
            setUpdateBill={(billId: string) => setEditBillId(billId)}
          />
        )}
        {editBillId && (
          <UpdateBillForm
            addDocumentToBill={addDocumentToBill}
            disableAddDocument={document.billId === editBillId}
            user={user as UserFragment}
            editBillId={editBillId}
            setUpdateBill={(billId: string) => setEditBillId(billId)}
            userRefetch={refetch}
          />
        )}
        {createBillResult.called &&
          updateDocumentResult.called &&
          !editBillId && (
            <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
              The bill has been saved
            </Alert>
          )}
        {createBillResult.error && !editBillId && (
          <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
            Error saving bill: {createBillResult.error.message}
          </Alert>
        )}
        {(createBillResult.error || updateDocumentResult.error) &&
          !editBillId && (
            <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
              Error saving bill:{" "}
              {createBillResult.error?.message ||
                updateDocumentResult.error?.message}
            </Alert>
          )}
      </Container>
    </Stack>
  );
}

export default DocumentToBill;
