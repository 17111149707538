import { Button } from "@mui/material";
import { useGetBillImagesQuery } from "../generated/graphql";

function Home({ user }: any) {

  const { data } = useGetBillImagesQuery({
    variables: {
      filterOptions: {
        isProcessed: false,
      },
    },
  });

  const count = data?.getBillImages.billImages?.length || 0;
  console.log(data)
  return (
    <div>
      <h2>Hello {user.attributes.email}</h2>
      <p>
        There {count > 1 || count === 0 ? "are" : "is"} {count} unprocessed bill submission
        {count > 1 || count === 0 ? "s" : ""}
      </p>
      <Button href="/submissions" size="small" variant="contained">
        Process Bills
      </Button>
    </div>
  );
}

export default Home;
