import { Button, TextField, Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import moment from "moment";

function ItemizedChargesInput({
  setBillingCodes,
  setAmountOwed,
}: {
  setBillingCodes: any;
  setAmountOwed: (amount: number) => void;
}) {
  const { control, register, getValues, watch } = useFormContext();
  const [totalAmount, setTotalAmount] = useState(0.0);
  const [currentBillingCodes, setCurrentBillingCodes] = useState(new Set());

  const dateString = moment().format("YYYY-MM-DD");
  const { fields, append, remove } = useFieldArray({
    control,
    name: "itemizedCharges",
  });

  const watchFieldArray = watch("itemizedCharges");

  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray[index],
    };
  });

  const areSetsEqual = (a: any, b: any) =>
    a.size === b.size && [...a].every((value) => b.has(value));

  useEffect(() => {
    const codes = controlledFields
      .filter((itemizedCharge: any) => itemizedCharge.code)
      .map((charge: any) => charge.code);
    const billingCodes = new Set(codes);
    if (!areSetsEqual(billingCodes, currentBillingCodes)) {
      setCurrentBillingCodes(billingCodes);
      setBillingCodes(billingCodes);
    }
  }, [
    setBillingCodes,
    currentBillingCodes,
    setCurrentBillingCodes,
    controlledFields,
  ]);

  useEffect(() => {
    let amountOwed = 0.0;
    controlledFields.forEach((itemizedCharge: any) => {
      amountOwed += parseFloat(itemizedCharge.billedAmount || 0);
    });
    setTotalAmount(amountOwed);
    setAmountOwed(amountOwed);
  }, [setAmountOwed, controlledFields]);

  return (
    <div className="input-list">
      <h5>Itemized Charges</h5>
      {controlledFields.map((itemizedCharge, index) => (
        <div key={itemizedCharge.id} className="input-list-row-item">
          <TextField
            size="small"
            label="Service Date"
            {...register(`itemizedCharges.${index}.date`)}
          />
          <TextField
            size="small"
            label="Billing Code"
            defaultValue={""}
            {...register(`itemizedCharges.${index}.code`)}
          />
          <TextField
            size="small"
            label="Description"
            defaultValue={""}
            {...register(`itemizedCharges.${index}.description`)}
          />
          <TextField
            size="small"
            label="Billed Amount"
            defaultValue={0.0}
            {...register(`itemizedCharges.${index}.billedAmount`)}
          />
          <Button onClick={() => remove(index)} variant="contained">
            X
          </Button>
        </div>
      ))}
      <Button
        onClick={() =>
          append({
            date:
              controlledFields.length > 0
                ? getValues(
                    `itemizedCharges.${controlledFields.length - 1}.date`
                  )
                : dateString,
          })
        }
        variant="contained"
      >
        +
      </Button>
      <Container style={{ fontWeight: "bold", marginTop: "10px" }}>
        Total Charges: ${totalAmount}
      </Container>
    </div>
  );
}

export default ItemizedChargesInput;
