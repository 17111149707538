import { useParams, Link } from "react-router-dom";
import {
  BillFragment,
  DocumentFragment,
  useGetBillsQuery,
  useGetUserQuery,
  useGetDocumentsQuery,
} from "../generated/graphql";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import { Container } from "@mui/material";

function User() {
  const params = useParams();
  const userId = params.userId || "test";

  const billResponse = useGetBillsQuery({
    variables: {
      userId: userId,
    },
  });
  const userResponse = useGetUserQuery({
    variables: {
      userId: userId,
    },
  });

  const documentsResponse = useGetDocumentsQuery({
    variables: {
      userId: userId,
      billId: "null",
    },
  });

  const documents: DocumentFragment[] =
    documentsResponse.data?.getDocuments?.documents || [];
  const bills: BillFragment[] = billResponse.data?.getBills?.bills || [];
  const user = userResponse.data?.getUser;

  return (
    <div>
      <h2>User: {user?.email}</h2>
      <Container style={{ margin: 20 }}>
        <div>Name: {user?.name}</div>
        <div>Phone: {user?.phoneNumber}</div>
        <div>ID: {user?.id}</div>
      </Container>

      <h3>Bills</h3>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="bills table">
          <TableHead>
            <TableRow>
              <TableCell>Invoice #</TableCell>
              <TableCell>AccountId</TableCell>
              <TableCell>Amount Owed</TableCell>
              <TableCell>Amount Due</TableCell>
              <TableCell>Paid?</TableCell>
              <TableCell>View / Edit</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {bills.map((bill) => (
              <TableRow key={bill.id}>
                <TableCell>{bill.invoiceNumber}</TableCell>
                <TableCell>{bill.accountId}</TableCell>
                <TableCell>{bill.amountOwed}</TableCell>
                <TableCell>{bill.amountDue}</TableCell>
                <TableCell>{bill.paidInFull}</TableCell>
                <TableCell>
                  <Link to={"bills/" + bill.id}>View / Edit</Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <h3 style={{ marginTop: "20px" }}>Unprocessed Documents</h3>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} size="small" aria-label="documents table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Date Sent</TableCell>
              <TableCell>Associated Bill ID</TableCell>
              <TableCell>Associate With Bill</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {documents.map((document) => (
              <TableRow key={document.id}>
                <TableCell>{document.name}</TableCell>
                <TableCell>{document.dateSent}</TableCell>
                <TableCell>{document.billId}</TableCell>
                <TableCell>
                  <Link to={"documents/" + document.id}>
                    Associate With Bill
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default User;
