import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { BillImages, useGetUserQuery } from "../../generated/graphql";

const UserCell = ({ userId }: { userId: string }) => {
  const userResponse = useGetUserQuery({
    variables: {
      userId,
    },
  });
  return (
    <TableCell component="th" scope="row">
      {userResponse.data?.getUser?.name}
    </TableCell>
  );
};

const BillSubmissionTable = ({ data }: { data: BillImages[] }) => {
  return (
    <TableContainer component={Paper}>
      <Table
        sx={{
          minWidth: 650,
          "& .MuiTableRow-root:hover": {
            backgroundColor: "primary.light",
          },
        }}
        aria-label="simple table"
      >
        <TableHead>
          <TableRow>
            <TableCell>User</TableCell>
            <TableCell>Bill Image ID</TableCell>
            <TableCell align="right">Processed</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow
              key={row.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 }, cursor: "pointer" }}
              onClick={()=> window.location.href = `/users/${row.userId}/submissions/${row.id}`}
            >
              <UserCell userId={row.userId} />
              <TableCell component="th" scope="row">
                {row.id}
              </TableCell>
              <TableCell align="right">{row.isProcessed?.toString()}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BillSubmissionTable;
