import { Alert, Box, Button, Container, Tab, TextField } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { BillFragment, BillInput, UserFragment } from "../../generated/graphql";
import ItemizedChargesInput from "./ItemizedChargesInput";
import InsuranceAlterationsInput from "./InsuranceAlterationsInput";
import BillIdentification from "./BillIdentificationInput";
import PaymentsInput from "./PaymentsInput";
import RecipientInput from "./RecipientInput";
import BillImageOptions from "./BillImageOptions";
import BillList from "../BillList";
import ProviderInput, { ProviderTabState } from "./ProviderInput";
import "./BillForm.css";
import moment from "moment";
import CircularProgress from "@mui/material/CircularProgress";
import { v4 as uuid } from "uuid";

const DEFAULT_BILL = (dateString: string) => {
  return {
    accountId: "",
    invoiceNumber: "",
    amountDue: 0,
    serviceDate: dateString,
    dueDate: dateString,
    statementDate: dateString,
    individualProviderName: "",
    itemizedCharges: [],
    insuranceAlterations: [],
    paidByUser: 0,
    payments: [],
    providerId: "",
    recipient: "self",
  };
};

function BillForm({
  user,
  formData,
  onSave,
  error,
  loading,
  userRefetch,
  isBillUpdate,
  setUpdateBill,
}: {
  user: UserFragment;
  formData: any | null;
  onSave: (billInput: BillInput) => any;
  error?: string | null;
  loading?: boolean;
  userRefetch: () => void;
  isBillUpdate: boolean;
  setUpdateBill?: (billId: string) => void;
}) {
  const [billingCodes, setBillingCodes] = useState<string[]>([]);
  const [amountOwed, setAmountOwed] = useState<number>(0);
  const [amountAfterIns, setAmountAfterIns] = useState<number>(0);
  const [tab, setTab] = useState("1");
  const dateString = moment().format("YYYY-MM-DD");

  const methods = useForm<BillFragment>({
    defaultValues: DEFAULT_BILL(dateString),
  });
  const [providerTabState, setProviderTabState] = useState<ProviderTabState>({
    zipcode: "",
    providerName: "",
    selectedProvider: formData?.provider,
    providerId:
      formData?.providerId || (formData?.provider ? formData.provider.id : ""),
    listOfProviders: [],
  });

  const { register, handleSubmit, reset, setValue } = methods;

  useEffect(() => {
    if (formData != null) {
      const changableFormData = { ...formData };
      if (changableFormData.recipient === null) {
        changableFormData.recipient = "self";
      }

      const newAlterations = [];
      for (const alteration of changableFormData?.insuranceAlterations || []) {
        const newInsuranceAlteration = { ...alteration };
        newInsuranceAlteration.billingCodes =
          newInsuranceAlteration.billingCodes.map((billingCode: string) => {
            return { value: billingCode, id: uuid() };
          });
        newAlterations.push(newInsuranceAlteration);
      }
      changableFormData.insuranceAlterations = newAlterations;

      reset(changableFormData);
    } else {
      reset(DEFAULT_BILL(dateString));
    }
    setProviderTabState({
      zipcode: "",
      providerName: "",
      selectedProvider: formData?.provider,
      providerId:
        formData?.providerId ||
        (formData?.provider ? formData?.provider.id : ""),
      listOfProviders: [],
    });
  }, [formData, reset, dateString]);

  const onSubmit = async (formData: any) => {
    try {
      const totalAmountDue =
        formData.itemizedCharges?.reduce(
          (acc: number, curr: any) => acc + Number(curr.billedAmount),
          0
        ) || Number(formData.amountOwed);
      const paidByUser =
        formData.payments?.reduce(
          (acc: number, curr: any) => acc + Number(curr.amount),
          0
        ) || 0;
      const paidByInsurance =
        formData.insuranceAlterations?.reduce(
          (acc: number, curr: any) => acc + Number(curr.amount),
          0
        ) || 0;

      const amountDue: number = totalAmountDue - paidByInsurance;
      const insuranceAlterations = formData.insuranceAlterations.map(
        (alteration: any) => {
          return {
            ...alteration,
            billingCodes: alteration.billingCodes.map(
              (val: { value: string }) => val.value
            ),
          };
        }
      );

      const billInput: BillInput = {
        recipient:
          formData?.recipient && formData.recipient === "self"
            ? null
            : formData.recipient,
        paidByUser: paidByUser || 0,
        amountDue,
        amountOwed: amountDue - paidByUser,
        paidInFull: paidByUser >= amountDue,
        invoiceNumber: formData?.invoiceNumber || "NA",
        accountId: formData?.accountId || "NA",
        insuranceAlterations,
        providerId: formData?.providerId || null,
        dueDate: formData?.dueDate,
        individualProviderName: formData?.individualProviderName,
        itemizedCharges: formData?.itemizedCharges || [],
        payments: formData?.payments || [],
        serviceDate: formData?.serviceDate || null,
        statementDate: formData?.statementDate || null,
      };

      await onSave(billInput);
      reset();
      setTab("1");
    } catch (error) {
      // Handle any errors from the mutation
      console.error(error);
    }
  };

  const setTotalAmountOwed = (amount: number) => {
    setAmountAfterIns(amount);
    setValue("amountOwed", amount);
  };

  return (
    <Container>
      <TabContext value={tab}>
        <Box sx={{ borderBottom: 1, borderColor: "divider", display: "block" }}>
          <TabList onChange={(event, index) => setTab(index)}>
            <Tab label="General" value="1" />
            <Tab label="Provider" value="2" />
            <Tab label="Itemized Charges / Payments" value="3" />
            {!isBillUpdate && <Tab label="Existing Bills" value="4" />}
            {formData?.billImageIds && formData?.billImageIds.length > 0 && (
              <Tab label="Bill Images" value="5" />
            )}
          </TabList>
        </Box>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <TabPanel value="1">
              <Container className="general-input">
                <BillIdentification
                  setUpdateBill={setUpdateBill}
                  isBillUpdate={isBillUpdate}
                  user={user}
                />
                <RecipientInput user={user} userRefetch={userRefetch} />
                <h5>Dates</h5>
                <TextField
                  style={{ margin: "5px" }}
                  size="medium"
                  label="Service Date"
                  {...register("serviceDate")}
                />
                <TextField
                  style={{ margin: "5px" }}
                  size="medium"
                  label="Statement Date"
                  {...register("statementDate")}
                />
                <TextField
                  style={{ margin: "5px" }}
                  size="medium"
                  label="Due Date"
                  {...register("dueDate")}
                />
              </Container>
            </TabPanel>
            <TabPanel value="2">
              <ProviderInput
                setProviderState={setProviderTabState}
                tabState={providerTabState}
              />
            </TabPanel>
            <TabPanel value="3">
              <ItemizedChargesInput
                setAmountOwed={setAmountOwed}
                setBillingCodes={setBillingCodes}
              />
              <InsuranceAlterationsInput
                billingCodes={billingCodes}
                amountOwedBeforeAlts={amountOwed}
                sendAmountAfterIns={setTotalAmountOwed}
              />
              <Alert
                className="alert"
                severity="info"
                style={{ marginTop: "10px" }}
              >
                Only fill in the amount owed if itemized charges aren't
                available to input
              </Alert>
              <TextField
                style={{ marginTop: "5px", width: "100%" }}
                size="small"
                label="Total Amount Owed"
                {...register("amountOwed")}
              />
              <PaymentsInput amountAfterIns={amountAfterIns} />
            </TabPanel>
            <TabPanel value="4">
              <BillList />
            </TabPanel>
            <TabPanel value="5">
              {formData?.id && (
                <BillImageOptions userId={user.id} billId={formData?.id} />
              )}
            </TabPanel>
            {error && <div>Error: {error}</div>}
            {loading && <div>Loading...</div>}
            <Button variant="contained" type="submit">
              {loading && <CircularProgress size="20px" />}
              {isBillUpdate ? "Update Existing Bill" : "Submit"}
            </Button>
          </form>
        </FormProvider>
      </TabContext>
    </Container>
  );
}

export default BillForm;
