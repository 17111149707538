import { useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import {
  useGetUnprocessedBillImagesByUserIdQuery,
  useGetDocumentsQuery,
  BillImages,
} from "../generated/graphql";
import { Container } from "@mui/material";
import BillSubmissions from "../components/BillSubmissions";


const BillSumbissionsByUser = () => {
  const userId = useParams().userId || "test";
  const documentId = useParams().documentId || "test";

  const { data, loading } = useGetUnprocessedBillImagesByUserIdQuery({
    variables: {
      userId,
      paginationOptions: {
        limit: 10,
      },
    },
  });

  const billImages = useMemo(
    () =>
      data?.getUnprocessedBillImagesByUserId?.billImages?.filter(
        (billImages) => billImages?.pending === false
      ),
    [data]
  );


  if (loading) {
    return <div>Loading...</div>;
  }

  if (billImages?.length === 0 || !billImages) {
    return <div>All bill images have been processed</div>;
  }

  return (
    <Container>
      <BillSubmissions data={billImages as BillImages[]} />
    </Container>
  );
};

export default BillSumbissionsByUser;
