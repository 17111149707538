import { BillImages, useGetBillImagesQuery } from "../generated/graphql";
import { useEffect, useState } from "react";
import { Container } from "@mui/material";
import BillSubmissionTable from "../components/BillSubmissions/BillSubmissionTable";

function BillSubmissions() {
  const [, setUserIds] = useState<Set<string>>(new Set());

  const { data } = useGetBillImagesQuery({
    variables: {
      filterOptions: {
        isProcessed: false,
      },
      paginationOptions: {
        limit: 10,
      },
    },
  });

  const billImages = data?.getBillImages.billImages;

  return (
    <Container style={{ marginTop: "10px" }}>
      <h1 style={{ marginBottom: "40px" }}>All Unprocessed Bill Submissions</h1>
      {billImages && <BillSubmissionTable data={billImages as BillImages[]} />}
    </Container>
  );
}

export default BillSubmissions;
