import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import {
  createBrowserRouter,
  RouterProvider,
  Routes,
  Route,
} from "react-router-dom";
import { Container } from "@mui/material";
import ApolloProviderWrapper from "./wrapper/ApolloProviderWrapper";
import DocumentListSelection from "./pages/DocumentListSelection";
import Users from "./pages/Users";
import User from "./pages/User";
import UpdateBill from "./pages/UpdateBill";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import "bootstrap/dist/css/bootstrap.min.css";
import "@aws-amplify/ui-react/styles.css";
import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import awsExports from "./aws-exports";
import { Amplify } from "aws-amplify";
import Home from "./pages/Home";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import BillSubmissions from "./pages/BillSubmissions";
import BillImagesToDocuments from "./pages/BillImagesToDocuments";
import DocumentToBill from "./pages/DocumentToBill";
import BillSumbissionsByUser from "./pages/BillSubmissionsByUser";
import UserDocuments from "./components/UserDocuments";

const APP_STAGE = process.env.REACT_APP_STAGE ?? "dev";
Amplify.configure(awsExports(APP_STAGE));

const router = createBrowserRouter([
  {
    path: "*",
    element: <Root />,
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

function Root() {
  return (
    <Authenticator>
      {({ signOut, user }) => (
        <>
          <Navbar expand="lg" className="bg-body-tertiary">
            <Container>
              <Navbar.Brand href="/">Oopda Health Admin Panel</Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto">
                  <Nav.Link href="/">Home</Nav.Link>
                  <Nav.Link href="/submissions">Bill Submissions</Nav.Link>
                  <Nav.Link href="/users">Users</Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
          <Container style={{ marginTop: "20px", maxWidth: "95%" }}>
            <Routes>
              <Route
                path="/"
                element={<Home user={user} signOut={signOut} />}
              />
              <Route path="/submissions" element={<BillSubmissions />} />
              <Route
                path="/users/:userId/submissions/"
                element={<BillSumbissionsByUser />}
              />
              <Route
                path="/users/:userId/submissions/:documentId"
                element={<BillImagesToDocuments />}
              />
              <Route path="/documents" element={<DocumentListSelection />} />
              <Route
                path="/users/:userId/documents"
                element={<UserDocuments />}
              />
              <Route
                path="/users/:userId/documents/:documentId"
                element={<DocumentToBill />}
              />
              <Route path="/users" element={<Users />}></Route>
              <Route path="/users/:userId" element={<User />}></Route>
              <Route
                path="/users/:userId/bills/:billId"
                element={<UpdateBill />}
              ></Route>
            </Routes>
            <div
              style={{
                position: "absolute",
                maxWidth: "60%",
                background: "white",
                zIndex: "100",
                top: "10%",
                left: "20%",
                margin: "auto",
                border: "0",
                borderRadius: "0.5rem",
                boxShadow: "0 0 0.5rem 0.25rem hsl(0 0% 0% / 10%)",
              }}
              id="create-provider-modal"
            ></div>
          </Container>
        </>
      )}
    </Authenticator>
  );
}

root.render(
  <React.StrictMode>
    <ApolloProviderWrapper>
      <RouterProvider router={router} />
    </ApolloProviderWrapper>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
