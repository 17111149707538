import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  AWSDate: any;
  AWSDateTime: any;
  AWSEmail: any;
  AWSIPAddress: any;
  AWSJSON: any;
  AWSPhone: any;
  AWSTime: any;
  AWSTimestamp: any;
  AWSURL: any;
};

export type Address = {
  __typename?: 'Address';
  city: Scalars['String'];
  state: Scalars['String'];
  street: Scalars['String'];
  streetAdditional?: Maybe<Scalars['String']>;
  zipCode: Scalars['String'];
};

export type AddressInput = {
  city: Scalars['String'];
  state: Scalars['String'];
  street: Scalars['String'];
  streetAdditional?: InputMaybe<Scalars['String']>;
  zipCode: Scalars['String'];
};

export type Auditable = {
  createdAt: Scalars['AWSDateTime'];
  updatedAt: Scalars['AWSDateTime'];
};

export enum AuthorType {
  Admin = 'ADMIN',
  User = 'USER'
}

export type Bill = Auditable & BillModel & {
  __typename?: 'Bill';
  accountId?: Maybe<Scalars['String']>;
  amountDue: Scalars['Float'];
  amountOwed: Scalars['Float'];
  billImageIds?: Maybe<Array<Scalars['ID']>>;
  createdAt: Scalars['AWSDateTime'];
  dueDate: Scalars['AWSDate'];
  feedback?: Maybe<Feedback>;
  id: Scalars['ID'];
  individualProviderName?: Maybe<Scalars['String']>;
  insuranceAlterations?: Maybe<Array<InsuranceAlteration>>;
  invoiceNumber?: Maybe<Scalars['String']>;
  itemizedCharges: Array<ItemizedCharge>;
  paidByUser: Scalars['Float'];
  paidInFull: Scalars['Boolean'];
  payments: Array<Payment>;
  provider: Provider;
  recipient?: Maybe<Scalars['ID']>;
  serviceDate: Scalars['AWSDate'];
  statementDate?: Maybe<Scalars['AWSDate']>;
  updatedAt: Scalars['AWSDateTime'];
  userId: Scalars['ID'];
};

export type BillFilterOptions = {
  accountId?: InputMaybe<Scalars['String']>;
  invoiceNumber?: InputMaybe<Scalars['String']>;
};

export type BillImages = Auditable & {
  __typename?: 'BillImages';
  billIds?: Maybe<Array<Scalars['ID']>>;
  createdAt: Scalars['AWSDateTime'];
  feedback?: Maybe<Feedback>;
  id: Scalars['ID'];
  imageUrls: Array<Scalars['String']>;
  isEOB?: Maybe<Scalars['Boolean']>;
  isProcessed?: Maybe<Scalars['Boolean']>;
  keys: Array<Scalars['String']>;
  pending?: Maybe<Scalars['Boolean']>;
  processedKeys?: Maybe<Array<Scalars['String']>>;
  s3Bucket: Scalars['String'];
  updatedAt: Scalars['AWSDateTime'];
  userId: Scalars['ID'];
};

export type BillImagesFilterOptions = {
  isProcessed?: InputMaybe<Scalars['Boolean']>;
};

export type BillImagesInput = {
  billId?: InputMaybe<Scalars['ID']>;
  isEOB?: InputMaybe<Scalars['Boolean']>;
  isProcessed?: InputMaybe<Scalars['Boolean']>;
  processedKeys?: InputMaybe<Array<Scalars['String']>>;
};

export type BillImagesPage = {
  __typename?: 'BillImagesPage';
  billImages?: Maybe<Array<BillImages>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type BillInput = {
  accountId?: InputMaybe<Scalars['String']>;
  amountDue: Scalars['Float'];
  amountOwed: Scalars['Float'];
  billImageIds?: InputMaybe<Array<Scalars['ID']>>;
  dueDate?: InputMaybe<Scalars['AWSDate']>;
  individualProviderName?: InputMaybe<Scalars['String']>;
  insuranceAlterations?: InputMaybe<Array<InsuranceAlterationInput>>;
  invoiceNumber?: InputMaybe<Scalars['String']>;
  itemizedCharges?: InputMaybe<Array<ItemizedChargeInput>>;
  paidByUser: Scalars['Float'];
  paidInFull: Scalars['Boolean'];
  payments?: InputMaybe<Array<PaymentInput>>;
  providerId?: InputMaybe<Scalars['ID']>;
  recipient?: InputMaybe<Scalars['ID']>;
  serviceDate?: InputMaybe<Scalars['AWSDate']>;
  statementDate?: InputMaybe<Scalars['AWSDate']>;
};

export type BillModel = {
  accountId?: Maybe<Scalars['String']>;
  billImageIds?: Maybe<Array<Scalars['ID']>>;
  dueDate: Scalars['AWSDate'];
  id: Scalars['ID'];
  individualProviderName?: Maybe<Scalars['String']>;
  insuranceAlterations?: Maybe<Array<InsuranceAlteration>>;
  invoiceNumber?: Maybe<Scalars['String']>;
  itemizedCharges: Array<ItemizedCharge>;
  payments: Array<Payment>;
  provider: Provider;
  recipient?: Maybe<Scalars['ID']>;
  serviceDate: Scalars['AWSDate'];
  statementDate?: Maybe<Scalars['AWSDate']>;
  userId: Scalars['ID'];
};

export type BillsPage = {
  __typename?: 'BillsPage';
  bills?: Maybe<Array<Bill>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type Document = Auditable & {
  __typename?: 'Document';
  billId?: Maybe<Scalars['ID']>;
  billImagesId?: Maybe<Scalars['String']>;
  createdAt: Scalars['AWSDateTime'];
  dateSent?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  imageUrls?: Maybe<Array<Scalars['String']>>;
  keys: Array<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  s3Bucket: Scalars['String'];
  updatedAt: Scalars['AWSDateTime'];
  userId: Scalars['ID'];
};

export type DocumentInput = {
  billId?: InputMaybe<Scalars['String']>;
  billImagesId?: InputMaybe<Scalars['String']>;
  dateSent?: InputMaybe<Scalars['String']>;
  keys: Array<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  s3Bucket: Scalars['String'];
};

export type DocumentsPage = {
  __typename?: 'DocumentsPage';
  documents: Array<Document>;
  nextToken?: Maybe<Scalars['String']>;
};

export type DraftBill = BillModel & {
  __typename?: 'DraftBill';
  accountId?: Maybe<Scalars['String']>;
  billImageIds?: Maybe<Array<Scalars['ID']>>;
  createdAt: Scalars['AWSDateTime'];
  dueDate: Scalars['AWSDate'];
  feedback?: Maybe<Feedback>;
  id: Scalars['ID'];
  individualProviderName?: Maybe<Scalars['String']>;
  insuranceAlterations: Array<InsuranceAlteration>;
  invoiceNumber?: Maybe<Scalars['String']>;
  itemizedCharges: Array<ItemizedCharge>;
  payments: Array<Payment>;
  potentialProviders?: Maybe<Array<Provider>>;
  provider: Provider;
  recipient?: Maybe<Scalars['ID']>;
  serviceDate: Scalars['AWSDate'];
  statementDate?: Maybe<Scalars['AWSDate']>;
  userId: Scalars['ID'];
};

export type FamilyMember = {
  __typename?: 'FamilyMember';
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
};

export type FamilyMemberInput = {
  firstName: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  lastName?: InputMaybe<Scalars['String']>;
};

export type Feedback = Auditable & {
  __typename?: 'Feedback';
  author?: Maybe<AuthorType>;
  billId?: Maybe<Scalars['ID']>;
  billImagesId?: Maybe<Scalars['ID']>;
  createdAt: Scalars['AWSDateTime'];
  id: Scalars['ID'];
  isDismissed: Scalars['Boolean'];
  message: Scalars['String'];
  updatedAt: Scalars['AWSDateTime'];
};

export type InsuranceAlteration = {
  __typename?: 'InsuranceAlteration';
  amount: Scalars['Float'];
  billingCodes: Array<Scalars['String']>;
  date: Scalars['AWSDate'];
  reason?: Maybe<Scalars['String']>;
  type: InsuranceAlterationType;
};

export type InsuranceAlterationInput = {
  amount: Scalars['Float'];
  billingCodes: Array<Scalars['String']>;
  date: Scalars['AWSDate'];
  reason?: InputMaybe<Scalars['String']>;
  type: InsuranceAlterationType;
};

export enum InsuranceAlterationType {
  Adjustment = 'ADJUSTMENT',
  Payment = 'PAYMENT'
}

export type ItemizedCharge = {
  __typename?: 'ItemizedCharge';
  billedAmount: Scalars['Float'];
  code?: Maybe<Scalars['String']>;
  date: Scalars['AWSDate'];
  description?: Maybe<Scalars['String']>;
  insuranceAdjustedAmount?: Maybe<Scalars['Float']>;
  insurancePaidAmount?: Maybe<Scalars['Float']>;
};

export type ItemizedChargeInput = {
  billedAmount?: InputMaybe<Scalars['Float']>;
  code?: InputMaybe<Scalars['String']>;
  date: Scalars['AWSDate'];
  description?: InputMaybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createBill: Scalars['ID'];
  createDocument: Document;
  createFamilyMember: FamilyMember;
  createProvider: Provider;
  deleteBill: Scalars['Boolean'];
  deleteProvider: Scalars['Boolean'];
  sendFeedback: Scalars['ID'];
  updateBill: Bill;
  updateBillImages: BillImages;
  updateDocument: Document;
  updateFeedback: Scalars['ID'];
  updateProvider: Provider;
  updateUser: User;
};


export type MutationCreateBillArgs = {
  bill: BillInput;
  userId: Scalars['ID'];
};


export type MutationCreateDocumentArgs = {
  document: DocumentInput;
  userId: Scalars['ID'];
};


export type MutationCreateFamilyMemberArgs = {
  familyMember: FamilyMemberInput;
  userId: Scalars['ID'];
};


export type MutationCreateProviderArgs = {
  provider: ProviderInput;
};


export type MutationDeleteBillArgs = {
  billId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type MutationDeleteProviderArgs = {
  providerId: Scalars['ID'];
};


export type MutationSendFeedbackArgs = {
  billImagesId: Scalars['ID'];
  feedback: Scalars['String'];
  userId: Scalars['ID'];
};


export type MutationUpdateBillArgs = {
  billId: Scalars['ID'];
  updatedBill?: InputMaybe<BillInput>;
  userId: Scalars['ID'];
};


export type MutationUpdateBillImagesArgs = {
  billImagesId: Scalars['ID'];
  billImagesInput: BillImagesInput;
  userId: Scalars['ID'];
};


export type MutationUpdateDocumentArgs = {
  document: UpdateDocumentInput;
  documentId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type MutationUpdateFeedbackArgs = {
  billImagesId: Scalars['ID'];
  feedback: Scalars['String'];
  feedbackId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type MutationUpdateProviderArgs = {
  provider: ProviderInput;
  providerId: Scalars['ID'];
};


export type MutationUpdateUserArgs = {
  updatedUser: UserInput;
  userId: Scalars['ID'];
};

export type PaginationOptions = {
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};

export type Payment = {
  __typename?: 'Payment';
  amount: Scalars['Float'];
  date: Scalars['AWSDate'];
  id: Scalars['ID'];
  type?: Maybe<PaymentType>;
};

export type PaymentInput = {
  amount: Scalars['Float'];
  date?: InputMaybe<Scalars['AWSDate']>;
  type?: InputMaybe<PaymentType>;
};

export enum PaymentType {
  Copay = 'COPAY',
  External = 'EXTERNAL'
}

export type Provider = Auditable & {
  __typename?: 'Provider';
  address: Address;
  availability?: Maybe<Scalars['String']>;
  billingWebAddress?: Maybe<Scalars['String']>;
  createdAt: Scalars['AWSDateTime'];
  id: Scalars['ID'];
  marketplaceId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  phoneNumber: Scalars['AWSPhone'];
  providerType?: Maybe<Scalars['String']>;
  saved?: Maybe<Scalars['Boolean']>;
  updatedAt: Scalars['AWSDateTime'];
};

export type ProviderInput = {
  address?: InputMaybe<AddressInput>;
  availability?: InputMaybe<Scalars['String']>;
  billingWebAddress?: InputMaybe<Scalars['String']>;
  marketplaceId?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['AWSPhone']>;
};

export type ProviderPage = {
  __typename?: 'ProviderPage';
  nextToken?: Maybe<Scalars['String']>;
  providers?: Maybe<Array<Provider>>;
};

export type Query = {
  __typename?: 'Query';
  getBill?: Maybe<Bill>;
  getBillImages: BillImagesPage;
  getBillImagesByUserIdBillId: BillImagesPage;
  getBills: BillsPage;
  getDocument: Document;
  getDocuments: DocumentsPage;
  getFeedback?: Maybe<Array<Feedback>>;
  getProvider?: Maybe<Provider>;
  getProvidersByZipCode: ProviderPage;
  getUnprocessedBillImagesByUserId: BillImagesPage;
  getUser?: Maybe<User>;
  getUsers: UserPage;
  searchForProviders?: Maybe<Array<Provider>>;
};


export type QueryGetBillArgs = {
  billId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type QueryGetBillImagesArgs = {
  filterOptions?: InputMaybe<BillImagesFilterOptions>;
  paginationOptions?: InputMaybe<PaginationOptions>;
};


export type QueryGetBillImagesByUserIdBillIdArgs = {
  billId: Scalars['ID'];
  paginationOptions?: InputMaybe<PaginationOptions>;
  userId: Scalars['ID'];
};


export type QueryGetBillsArgs = {
  filterOptions?: InputMaybe<BillFilterOptions>;
  paginationOptions?: InputMaybe<PaginationOptions>;
  userId: Scalars['ID'];
};


export type QueryGetDocumentArgs = {
  documentId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type QueryGetDocumentsArgs = {
  billId: Scalars['ID'];
  paginationOptions?: InputMaybe<PaginationOptions>;
  userId: Scalars['ID'];
};


export type QueryGetFeedbackArgs = {
  referenceId?: InputMaybe<Scalars['String']>;
  userId: Scalars['ID'];
};


export type QueryGetProviderArgs = {
  providerId: Scalars['ID'];
};


export type QueryGetProvidersByZipCodeArgs = {
  zipCode: Scalars['String'];
};


export type QueryGetUnprocessedBillImagesByUserIdArgs = {
  paginationOptions?: InputMaybe<PaginationOptions>;
  userId: Scalars['ID'];
};


export type QueryGetUserArgs = {
  userId: Scalars['ID'];
};


export type QueryGetUsersArgs = {
  filterOptions?: InputMaybe<UserFilterOptions>;
  paginationOptions?: InputMaybe<PaginationOptions>;
};


export type QuerySearchForProvidersArgs = {
  query: Scalars['String'];
  zipcode: Scalars['String'];
};

export type Summary = Auditable & {
  __typename?: 'Summary';
  amountDue: Scalars['Float'];
  amountOverdue: Scalars['Float'];
  amountOwed: Scalars['Float'];
  amountUpcomingDue: Scalars['Float'];
  createdAt: Scalars['AWSDateTime'];
  nextUpcomingBillIds: Array<Maybe<Scalars['ID']>>;
  overdueBillIds: Array<Maybe<Scalars['ID']>>;
  updatedAt: Scalars['AWSDateTime'];
};

export type UpdateDocumentInput = {
  billId?: InputMaybe<Scalars['String']>;
  billImagesId?: InputMaybe<Scalars['String']>;
  dateSent?: InputMaybe<Scalars['String']>;
  keys?: InputMaybe<Array<Scalars['String']>>;
  name?: InputMaybe<Scalars['String']>;
  s3Bucket?: InputMaybe<Scalars['String']>;
};

export type UploadEndpointAndToken = {
  __typename?: 'UploadEndpointAndToken';
  putUrls: Array<Maybe<Scalars['String']>>;
  token: Scalars['String'];
};

export type User = Auditable & {
  __typename?: 'User';
  acceptedTerms: Scalars['Boolean'];
  address?: Maybe<Address>;
  cognitoId: Scalars['ID'];
  createdAt: Scalars['AWSDateTime'];
  email?: Maybe<Scalars['AWSEmail']>;
  familyMembers: Array<FamilyMember>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['AWSPhone']>;
  updatedAt: Scalars['AWSDateTime'];
};

export type UserFilterOptions = {
  email?: InputMaybe<Scalars['String']>;
};

export type UserInput = {
  acceptedTerms?: InputMaybe<Scalars['Boolean']>;
  address?: InputMaybe<AddressInput>;
  email?: InputMaybe<Scalars['AWSEmail']>;
  familyMembers?: InputMaybe<Array<FamilyMemberInput>>;
  name?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['AWSPhone']>;
};

export type UserPage = {
  __typename?: 'UserPage';
  nextToken?: Maybe<Scalars['String']>;
  users?: Maybe<Array<User>>;
};

export type BillFragment = { __typename?: 'Bill', id: string, userId: string, accountId?: string | null, invoiceNumber?: string | null, statementDate?: any | null, amountDue: number, amountOwed: number, dueDate: any, individualProviderName?: string | null, recipient?: string | null, serviceDate: any, paidInFull: boolean, billImageIds?: Array<string> | null, itemizedCharges: Array<{ __typename?: 'ItemizedCharge', date: any, billedAmount: number, code?: string | null, description?: string | null }>, insuranceAlterations?: Array<{ __typename?: 'InsuranceAlteration', amount: number, billingCodes: Array<string>, date: any, reason?: string | null, type: InsuranceAlterationType }> | null, provider: { __typename?: 'Provider', id: string, availability?: string | null, billingWebAddress?: string | null, name: string, phoneNumber: any, saved?: boolean | null, marketplaceId?: string | null, address: { __typename?: 'Address', city: string, state: string, street: string, streetAdditional?: string | null, zipCode: string } }, payments: Array<{ __typename?: 'Payment', amount: number, date: any, type?: PaymentType | null }> };

export type BillImagesFragment = { __typename?: 'BillImages', billIds?: Array<string> | null, userId: string, id: string, s3Bucket: string, keys: Array<string>, isProcessed?: boolean | null, isEOB?: boolean | null, pending?: boolean | null, imageUrls: Array<string>, processedKeys?: Array<string> | null };

export type DocumentFragment = { __typename?: 'Document', id: string, keys: Array<string>, imageUrls?: Array<string> | null, billId?: string | null, name?: string | null, dateSent?: string | null, billImagesId?: string | null };

export type DraftBillFragment = { __typename?: 'DraftBill', accountId?: string | null, createdAt: any, dueDate: any, id: string, individualProviderName?: string | null, invoiceNumber?: string | null, recipient?: string | null, serviceDate: any, statementDate?: any | null, userId: string, insuranceAlterations: Array<{ __typename?: 'InsuranceAlteration', amount: number, billingCodes: Array<string>, date: any, reason?: string | null, type: InsuranceAlterationType }>, itemizedCharges: Array<{ __typename?: 'ItemizedCharge', date: any, billedAmount: number, code?: string | null, description?: string | null }>, payments: Array<{ __typename?: 'Payment', amount: number, date: any, type?: PaymentType | null }>, provider: { __typename?: 'Provider', id: string, availability?: string | null, billingWebAddress?: string | null, name: string, phoneNumber: any, saved?: boolean | null, marketplaceId?: string | null, address: { __typename?: 'Address', city: string, state: string, street: string, streetAdditional?: string | null, zipCode: string } } };

export type FamilyMemberFragment = { __typename?: 'FamilyMember', firstName: string, lastName?: string | null, id: string };

export type InsuranceAlterationFragment = { __typename?: 'InsuranceAlteration', amount: number, billingCodes: Array<string>, date: any, reason?: string | null, type: InsuranceAlterationType };

export type ItemizedChargeFragment = { __typename?: 'ItemizedCharge', date: any, billedAmount: number, code?: string | null, description?: string | null };

export type PaymentFragment = { __typename?: 'Payment', amount: number, date: any, type?: PaymentType | null };

export type ProviderFragment = { __typename?: 'Provider', id: string, availability?: string | null, billingWebAddress?: string | null, name: string, phoneNumber: any, saved?: boolean | null, marketplaceId?: string | null, address: { __typename?: 'Address', city: string, state: string, street: string, streetAdditional?: string | null, zipCode: string } };

export type UserFragment = { __typename?: 'User', id: string, name?: string | null, email?: any | null, phoneNumber?: any | null, address?: { __typename?: 'Address', street: string, streetAdditional?: string | null, city: string, state: string, zipCode: string } | null, familyMembers: Array<{ __typename?: 'FamilyMember', id: string, firstName: string, lastName?: string | null }> };

export type CreateBillMutationVariables = Exact<{
  bill: BillInput;
  userId: Scalars['ID'];
}>;


export type CreateBillMutation = { __typename?: 'Mutation', createBill: string };

export type CreateDocumentMutationVariables = Exact<{
  document: DocumentInput;
  userId: Scalars['ID'];
}>;


export type CreateDocumentMutation = { __typename?: 'Mutation', createDocument: { __typename?: 'Document', id: string, keys: Array<string>, imageUrls?: Array<string> | null, billId?: string | null, name?: string | null, dateSent?: string | null, billImagesId?: string | null } };

export type CreateFamilyMemberMutationVariables = Exact<{
  familyMember: FamilyMemberInput;
  userId: Scalars['ID'];
}>;


export type CreateFamilyMemberMutation = { __typename?: 'Mutation', createFamilyMember: { __typename?: 'FamilyMember', firstName: string, lastName?: string | null, id: string } };

export type CreateProviderMutationVariables = Exact<{
  provider: ProviderInput;
}>;


export type CreateProviderMutation = { __typename?: 'Mutation', createProvider: { __typename?: 'Provider', id: string, availability?: string | null, billingWebAddress?: string | null, name: string, phoneNumber: any, saved?: boolean | null, marketplaceId?: string | null, address: { __typename?: 'Address', city: string, state: string, street: string, streetAdditional?: string | null, zipCode: string } } };

export type UpdateBillMutationVariables = Exact<{
  billId: Scalars['ID'];
  updatedBill: BillInput;
  userId: Scalars['ID'];
}>;


export type UpdateBillMutation = { __typename?: 'Mutation', updateBill: { __typename?: 'Bill', id: string, userId: string, accountId?: string | null, invoiceNumber?: string | null, statementDate?: any | null, amountDue: number, amountOwed: number, dueDate: any, individualProviderName?: string | null, recipient?: string | null, serviceDate: any, paidInFull: boolean, billImageIds?: Array<string> | null, itemizedCharges: Array<{ __typename?: 'ItemizedCharge', date: any, billedAmount: number, code?: string | null, description?: string | null }>, insuranceAlterations?: Array<{ __typename?: 'InsuranceAlteration', amount: number, billingCodes: Array<string>, date: any, reason?: string | null, type: InsuranceAlterationType }> | null, provider: { __typename?: 'Provider', id: string, availability?: string | null, billingWebAddress?: string | null, name: string, phoneNumber: any, saved?: boolean | null, marketplaceId?: string | null, address: { __typename?: 'Address', city: string, state: string, street: string, streetAdditional?: string | null, zipCode: string } }, payments: Array<{ __typename?: 'Payment', amount: number, date: any, type?: PaymentType | null }> } };

export type UpdateBillImagesMutationVariables = Exact<{
  billImagesId: Scalars['ID'];
  billImagesInput: BillImagesInput;
  userId: Scalars['ID'];
}>;


export type UpdateBillImagesMutation = { __typename?: 'Mutation', updateBillImages: { __typename?: 'BillImages', billIds?: Array<string> | null, userId: string, id: string, s3Bucket: string, keys: Array<string>, isProcessed?: boolean | null, isEOB?: boolean | null, pending?: boolean | null, imageUrls: Array<string>, processedKeys?: Array<string> | null } };

export type UpdateDocumentMutationVariables = Exact<{
  userId: Scalars['ID'];
  documentId: Scalars['ID'];
  document: UpdateDocumentInput;
}>;


export type UpdateDocumentMutation = { __typename?: 'Mutation', updateDocument: { __typename?: 'Document', id: string } };

export type UpdateProviderMutationVariables = Exact<{
  provider: ProviderInput;
  providerId: Scalars['ID'];
}>;


export type UpdateProviderMutation = { __typename?: 'Mutation', updateProvider: { __typename?: 'Provider', id: string, availability?: string | null, billingWebAddress?: string | null, name: string, phoneNumber: any, saved?: boolean | null, marketplaceId?: string | null, address: { __typename?: 'Address', city: string, state: string, street: string, streetAdditional?: string | null, zipCode: string } } };

export type UpdateUserMutationVariables = Exact<{
  updatedUser: UserInput;
  userId: Scalars['ID'];
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser: { __typename?: 'User', id: string, name?: string | null, email?: any | null, phoneNumber?: any | null, address?: { __typename?: 'Address', street: string, streetAdditional?: string | null, city: string, state: string, zipCode: string } | null, familyMembers: Array<{ __typename?: 'FamilyMember', id: string, firstName: string, lastName?: string | null }> } };

export type GetBillQueryVariables = Exact<{
  userId: Scalars['ID'];
  billId: Scalars['ID'];
}>;


export type GetBillQuery = { __typename?: 'Query', getBill?: { __typename?: 'Bill', id: string, userId: string, accountId?: string | null, invoiceNumber?: string | null, statementDate?: any | null, amountDue: number, amountOwed: number, dueDate: any, individualProviderName?: string | null, recipient?: string | null, serviceDate: any, paidInFull: boolean, billImageIds?: Array<string> | null, itemizedCharges: Array<{ __typename?: 'ItemizedCharge', date: any, billedAmount: number, code?: string | null, description?: string | null }>, insuranceAlterations?: Array<{ __typename?: 'InsuranceAlteration', amount: number, billingCodes: Array<string>, date: any, reason?: string | null, type: InsuranceAlterationType }> | null, provider: { __typename?: 'Provider', id: string, availability?: string | null, billingWebAddress?: string | null, name: string, phoneNumber: any, saved?: boolean | null, marketplaceId?: string | null, address: { __typename?: 'Address', city: string, state: string, street: string, streetAdditional?: string | null, zipCode: string } }, payments: Array<{ __typename?: 'Payment', amount: number, date: any, type?: PaymentType | null }> } | null };

export type GetBillImagesQueryVariables = Exact<{
  filterOptions?: InputMaybe<BillImagesFilterOptions>;
  paginationOptions?: InputMaybe<PaginationOptions>;
}>;


export type GetBillImagesQuery = { __typename?: 'Query', getBillImages: { __typename?: 'BillImagesPage', nextToken?: string | null, billImages?: Array<{ __typename?: 'BillImages', billIds?: Array<string> | null, userId: string, id: string, s3Bucket: string, keys: Array<string>, isProcessed?: boolean | null, isEOB?: boolean | null, pending?: boolean | null, imageUrls: Array<string>, processedKeys?: Array<string> | null }> | null } };

export type GetBillImagesByUserIdBillIdQueryVariables = Exact<{
  userId: Scalars['ID'];
  billId: Scalars['ID'];
  paginationOptions?: InputMaybe<PaginationOptions>;
}>;


export type GetBillImagesByUserIdBillIdQuery = { __typename?: 'Query', getBillImagesByUserIdBillId: { __typename?: 'BillImagesPage', billImages?: Array<{ __typename?: 'BillImages', billIds?: Array<string> | null, userId: string, id: string, s3Bucket: string, keys: Array<string>, isProcessed?: boolean | null, isEOB?: boolean | null, pending?: boolean | null, imageUrls: Array<string>, processedKeys?: Array<string> | null }> | null } };

export type GetBillsQueryVariables = Exact<{
  userId: Scalars['ID'];
  filterOptions?: InputMaybe<BillFilterOptions>;
  paginationOptions?: InputMaybe<PaginationOptions>;
}>;


export type GetBillsQuery = { __typename?: 'Query', getBills: { __typename?: 'BillsPage', nextToken?: string | null, bills?: Array<{ __typename?: 'Bill', id: string, userId: string, accountId?: string | null, invoiceNumber?: string | null, statementDate?: any | null, amountDue: number, amountOwed: number, dueDate: any, individualProviderName?: string | null, recipient?: string | null, serviceDate: any, paidInFull: boolean, billImageIds?: Array<string> | null, itemizedCharges: Array<{ __typename?: 'ItemizedCharge', date: any, billedAmount: number, code?: string | null, description?: string | null }>, insuranceAlterations?: Array<{ __typename?: 'InsuranceAlteration', amount: number, billingCodes: Array<string>, date: any, reason?: string | null, type: InsuranceAlterationType }> | null, provider: { __typename?: 'Provider', id: string, availability?: string | null, billingWebAddress?: string | null, name: string, phoneNumber: any, saved?: boolean | null, marketplaceId?: string | null, address: { __typename?: 'Address', city: string, state: string, street: string, streetAdditional?: string | null, zipCode: string } }, payments: Array<{ __typename?: 'Payment', amount: number, date: any, type?: PaymentType | null }> }> | null } };

export type GetDocumentQueryVariables = Exact<{
  documentId: Scalars['ID'];
  userId: Scalars['ID'];
}>;


export type GetDocumentQuery = { __typename?: 'Query', getDocument: { __typename?: 'Document', id: string, keys: Array<string>, imageUrls?: Array<string> | null, billId?: string | null, name?: string | null, dateSent?: string | null, billImagesId?: string | null } };

export type GetDocumentsQueryVariables = Exact<{
  userId: Scalars['ID'];
  billId: Scalars['ID'];
  paginationOptions?: InputMaybe<PaginationOptions>;
}>;


export type GetDocumentsQuery = { __typename?: 'Query', getDocuments: { __typename?: 'DocumentsPage', documents: Array<{ __typename?: 'Document', id: string, keys: Array<string>, imageUrls?: Array<string> | null, billId?: string | null, name?: string | null, dateSent?: string | null, billImagesId?: string | null }> } };

export type GetProviderQueryVariables = Exact<{
  providerId: Scalars['ID'];
}>;


export type GetProviderQuery = { __typename?: 'Query', getProvider?: { __typename?: 'Provider', id: string, availability?: string | null, billingWebAddress?: string | null, name: string, phoneNumber: any, saved?: boolean | null, marketplaceId?: string | null, address: { __typename?: 'Address', city: string, state: string, street: string, streetAdditional?: string | null, zipCode: string } } | null };

export type GetProvidersByZipCodeQueryVariables = Exact<{
  zipCode: Scalars['String'];
}>;


export type GetProvidersByZipCodeQuery = { __typename?: 'Query', getProvidersByZipCode: { __typename?: 'ProviderPage', nextToken?: string | null, providers?: Array<{ __typename?: 'Provider', id: string, availability?: string | null, billingWebAddress?: string | null, name: string, phoneNumber: any, saved?: boolean | null, marketplaceId?: string | null, address: { __typename?: 'Address', city: string, state: string, street: string, streetAdditional?: string | null, zipCode: string } }> | null } };

export type GetUnprocessedBillImagesByUserIdQueryVariables = Exact<{
  userId: Scalars['ID'];
  paginationOptions?: InputMaybe<PaginationOptions>;
}>;


export type GetUnprocessedBillImagesByUserIdQuery = { __typename?: 'Query', getUnprocessedBillImagesByUserId: { __typename?: 'BillImagesPage', nextToken?: string | null, billImages?: Array<{ __typename?: 'BillImages', billIds?: Array<string> | null, userId: string, id: string, s3Bucket: string, keys: Array<string>, isProcessed?: boolean | null, isEOB?: boolean | null, pending?: boolean | null, imageUrls: Array<string>, processedKeys?: Array<string> | null }> | null } };

export type GetUserQueryVariables = Exact<{
  userId: Scalars['ID'];
}>;


export type GetUserQuery = { __typename?: 'Query', getUser?: { __typename?: 'User', id: string, name?: string | null, email?: any | null, phoneNumber?: any | null, address?: { __typename?: 'Address', street: string, streetAdditional?: string | null, city: string, state: string, zipCode: string } | null, familyMembers: Array<{ __typename?: 'FamilyMember', id: string, firstName: string, lastName?: string | null }> } | null };

export type GetUsersQueryVariables = Exact<{
  paginationOptions?: InputMaybe<PaginationOptions>;
}>;


export type GetUsersQuery = { __typename?: 'Query', getUsers: { __typename?: 'UserPage', nextToken?: string | null, users?: Array<{ __typename?: 'User', id: string, name?: string | null, email?: any | null }> | null } };

export type SearchForProvidersQueryVariables = Exact<{
  zipcode: Scalars['String'];
  query: Scalars['String'];
}>;


export type SearchForProvidersQuery = { __typename?: 'Query', searchForProviders?: Array<{ __typename?: 'Provider', id: string, availability?: string | null, billingWebAddress?: string | null, name: string, phoneNumber: any, saved?: boolean | null, marketplaceId?: string | null, address: { __typename?: 'Address', city: string, state: string, street: string, streetAdditional?: string | null, zipCode: string } }> | null };

export const ItemizedChargeFragmentDoc = gql`
    fragment ItemizedCharge on ItemizedCharge {
  date
  billedAmount
  code
  description
}
    `;
export const InsuranceAlterationFragmentDoc = gql`
    fragment InsuranceAlteration on InsuranceAlteration {
  amount
  billingCodes
  date
  reason
  type
}
    `;
export const ProviderFragmentDoc = gql`
    fragment Provider on Provider {
  id
  address {
    city
    state
    street
    streetAdditional
    zipCode
  }
  availability
  billingWebAddress
  name
  phoneNumber
  saved
  marketplaceId
}
    `;
export const PaymentFragmentDoc = gql`
    fragment Payment on Payment {
  amount
  date
  type
}
    `;
export const BillFragmentDoc = gql`
    fragment Bill on Bill {
  id
  userId
  accountId
  invoiceNumber
  statementDate
  amountDue
  amountOwed
  dueDate
  individualProviderName
  itemizedCharges {
    ...ItemizedCharge
  }
  insuranceAlterations {
    ...InsuranceAlteration
  }
  provider {
    ...Provider
  }
  payments {
    ...Payment
  }
  recipient
  serviceDate
  paidInFull
  billImageIds
}
    ${ItemizedChargeFragmentDoc}
${InsuranceAlterationFragmentDoc}
${ProviderFragmentDoc}
${PaymentFragmentDoc}`;
export const BillImagesFragmentDoc = gql`
    fragment BillImages on BillImages {
  billIds
  userId
  id
  s3Bucket
  keys
  isProcessed
  isEOB
  pending
  imageUrls
  processedKeys
}
    `;
export const DocumentFragmentDoc = gql`
    fragment Document on Document {
  id
  keys
  imageUrls
  billId
  name
  dateSent
  billImagesId
}
    `;
export const DraftBillFragmentDoc = gql`
    fragment DraftBill on DraftBill {
  accountId
  createdAt
  dueDate
  id
  individualProviderName
  insuranceAlterations {
    ...InsuranceAlteration
  }
  itemizedCharges {
    ...ItemizedCharge
  }
  invoiceNumber
  payments {
    ...Payment
  }
  provider {
    ...Provider
  }
  recipient
  serviceDate
  statementDate
  userId
}
    ${InsuranceAlterationFragmentDoc}
${ItemizedChargeFragmentDoc}
${PaymentFragmentDoc}
${ProviderFragmentDoc}`;
export const FamilyMemberFragmentDoc = gql`
    fragment FamilyMember on FamilyMember {
  firstName
  lastName
  id
}
    `;
export const UserFragmentDoc = gql`
    fragment User on User {
  id
  name
  email
  phoneNumber
  address {
    street
    streetAdditional
    city
    state
    zipCode
  }
  familyMembers {
    id
    ...FamilyMember
  }
}
    ${FamilyMemberFragmentDoc}`;
export const CreateBillDocument = gql`
    mutation CreateBill($bill: BillInput!, $userId: ID!) {
  createBill(bill: $bill, userId: $userId)
}
    `;
export type CreateBillMutationFn = Apollo.MutationFunction<CreateBillMutation, CreateBillMutationVariables>;

/**
 * __useCreateBillMutation__
 *
 * To run a mutation, you first call `useCreateBillMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBillMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBillMutation, { data, loading, error }] = useCreateBillMutation({
 *   variables: {
 *      bill: // value for 'bill'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useCreateBillMutation(baseOptions?: Apollo.MutationHookOptions<CreateBillMutation, CreateBillMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateBillMutation, CreateBillMutationVariables>(CreateBillDocument, options);
      }
export type CreateBillMutationHookResult = ReturnType<typeof useCreateBillMutation>;
export type CreateBillMutationResult = Apollo.MutationResult<CreateBillMutation>;
export type CreateBillMutationOptions = Apollo.BaseMutationOptions<CreateBillMutation, CreateBillMutationVariables>;
export const CreateDocumentDocument = gql`
    mutation CreateDocument($document: DocumentInput!, $userId: ID!) {
  createDocument(document: $document, userId: $userId) {
    ...Document
  }
}
    ${DocumentFragmentDoc}`;
export type CreateDocumentMutationFn = Apollo.MutationFunction<CreateDocumentMutation, CreateDocumentMutationVariables>;

/**
 * __useCreateDocumentMutation__
 *
 * To run a mutation, you first call `useCreateDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDocumentMutation, { data, loading, error }] = useCreateDocumentMutation({
 *   variables: {
 *      document: // value for 'document'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useCreateDocumentMutation(baseOptions?: Apollo.MutationHookOptions<CreateDocumentMutation, CreateDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateDocumentMutation, CreateDocumentMutationVariables>(CreateDocumentDocument, options);
      }
export type CreateDocumentMutationHookResult = ReturnType<typeof useCreateDocumentMutation>;
export type CreateDocumentMutationResult = Apollo.MutationResult<CreateDocumentMutation>;
export type CreateDocumentMutationOptions = Apollo.BaseMutationOptions<CreateDocumentMutation, CreateDocumentMutationVariables>;
export const CreateFamilyMemberDocument = gql`
    mutation CreateFamilyMember($familyMember: FamilyMemberInput!, $userId: ID!) {
  createFamilyMember(familyMember: $familyMember, userId: $userId) {
    ...FamilyMember
  }
}
    ${FamilyMemberFragmentDoc}`;
export type CreateFamilyMemberMutationFn = Apollo.MutationFunction<CreateFamilyMemberMutation, CreateFamilyMemberMutationVariables>;

/**
 * __useCreateFamilyMemberMutation__
 *
 * To run a mutation, you first call `useCreateFamilyMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFamilyMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFamilyMemberMutation, { data, loading, error }] = useCreateFamilyMemberMutation({
 *   variables: {
 *      familyMember: // value for 'familyMember'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useCreateFamilyMemberMutation(baseOptions?: Apollo.MutationHookOptions<CreateFamilyMemberMutation, CreateFamilyMemberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFamilyMemberMutation, CreateFamilyMemberMutationVariables>(CreateFamilyMemberDocument, options);
      }
export type CreateFamilyMemberMutationHookResult = ReturnType<typeof useCreateFamilyMemberMutation>;
export type CreateFamilyMemberMutationResult = Apollo.MutationResult<CreateFamilyMemberMutation>;
export type CreateFamilyMemberMutationOptions = Apollo.BaseMutationOptions<CreateFamilyMemberMutation, CreateFamilyMemberMutationVariables>;
export const CreateProviderDocument = gql`
    mutation CreateProvider($provider: ProviderInput!) {
  createProvider(provider: $provider) {
    ...Provider
  }
}
    ${ProviderFragmentDoc}`;
export type CreateProviderMutationFn = Apollo.MutationFunction<CreateProviderMutation, CreateProviderMutationVariables>;

/**
 * __useCreateProviderMutation__
 *
 * To run a mutation, you first call `useCreateProviderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProviderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProviderMutation, { data, loading, error }] = useCreateProviderMutation({
 *   variables: {
 *      provider: // value for 'provider'
 *   },
 * });
 */
export function useCreateProviderMutation(baseOptions?: Apollo.MutationHookOptions<CreateProviderMutation, CreateProviderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProviderMutation, CreateProviderMutationVariables>(CreateProviderDocument, options);
      }
export type CreateProviderMutationHookResult = ReturnType<typeof useCreateProviderMutation>;
export type CreateProviderMutationResult = Apollo.MutationResult<CreateProviderMutation>;
export type CreateProviderMutationOptions = Apollo.BaseMutationOptions<CreateProviderMutation, CreateProviderMutationVariables>;
export const UpdateBillDocument = gql`
    mutation UpdateBill($billId: ID!, $updatedBill: BillInput!, $userId: ID!) {
  updateBill(billId: $billId, updatedBill: $updatedBill, userId: $userId) {
    ...Bill
  }
}
    ${BillFragmentDoc}`;
export type UpdateBillMutationFn = Apollo.MutationFunction<UpdateBillMutation, UpdateBillMutationVariables>;

/**
 * __useUpdateBillMutation__
 *
 * To run a mutation, you first call `useUpdateBillMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBillMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBillMutation, { data, loading, error }] = useUpdateBillMutation({
 *   variables: {
 *      billId: // value for 'billId'
 *      updatedBill: // value for 'updatedBill'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUpdateBillMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBillMutation, UpdateBillMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBillMutation, UpdateBillMutationVariables>(UpdateBillDocument, options);
      }
export type UpdateBillMutationHookResult = ReturnType<typeof useUpdateBillMutation>;
export type UpdateBillMutationResult = Apollo.MutationResult<UpdateBillMutation>;
export type UpdateBillMutationOptions = Apollo.BaseMutationOptions<UpdateBillMutation, UpdateBillMutationVariables>;
export const UpdateBillImagesDocument = gql`
    mutation UpdateBillImages($billImagesId: ID!, $billImagesInput: BillImagesInput!, $userId: ID!) {
  updateBillImages(
    billImagesId: $billImagesId
    billImagesInput: $billImagesInput
    userId: $userId
  ) {
    ...BillImages
  }
}
    ${BillImagesFragmentDoc}`;
export type UpdateBillImagesMutationFn = Apollo.MutationFunction<UpdateBillImagesMutation, UpdateBillImagesMutationVariables>;

/**
 * __useUpdateBillImagesMutation__
 *
 * To run a mutation, you first call `useUpdateBillImagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBillImagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBillImagesMutation, { data, loading, error }] = useUpdateBillImagesMutation({
 *   variables: {
 *      billImagesId: // value for 'billImagesId'
 *      billImagesInput: // value for 'billImagesInput'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUpdateBillImagesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBillImagesMutation, UpdateBillImagesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBillImagesMutation, UpdateBillImagesMutationVariables>(UpdateBillImagesDocument, options);
      }
export type UpdateBillImagesMutationHookResult = ReturnType<typeof useUpdateBillImagesMutation>;
export type UpdateBillImagesMutationResult = Apollo.MutationResult<UpdateBillImagesMutation>;
export type UpdateBillImagesMutationOptions = Apollo.BaseMutationOptions<UpdateBillImagesMutation, UpdateBillImagesMutationVariables>;
export const UpdateDocumentDocument = gql`
    mutation UpdateDocument($userId: ID!, $documentId: ID!, $document: UpdateDocumentInput!) {
  updateDocument(documentId: $documentId, userId: $userId, document: $document) {
    id
  }
}
    `;
export type UpdateDocumentMutationFn = Apollo.MutationFunction<UpdateDocumentMutation, UpdateDocumentMutationVariables>;

/**
 * __useUpdateDocumentMutation__
 *
 * To run a mutation, you first call `useUpdateDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDocumentMutation, { data, loading, error }] = useUpdateDocumentMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      documentId: // value for 'documentId'
 *      document: // value for 'document'
 *   },
 * });
 */
export function useUpdateDocumentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateDocumentMutation, UpdateDocumentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateDocumentMutation, UpdateDocumentMutationVariables>(UpdateDocumentDocument, options);
      }
export type UpdateDocumentMutationHookResult = ReturnType<typeof useUpdateDocumentMutation>;
export type UpdateDocumentMutationResult = Apollo.MutationResult<UpdateDocumentMutation>;
export type UpdateDocumentMutationOptions = Apollo.BaseMutationOptions<UpdateDocumentMutation, UpdateDocumentMutationVariables>;
export const UpdateProviderDocument = gql`
    mutation UpdateProvider($provider: ProviderInput!, $providerId: ID!) {
  updateProvider(provider: $provider, providerId: $providerId) {
    ...Provider
  }
}
    ${ProviderFragmentDoc}`;
export type UpdateProviderMutationFn = Apollo.MutationFunction<UpdateProviderMutation, UpdateProviderMutationVariables>;

/**
 * __useUpdateProviderMutation__
 *
 * To run a mutation, you first call `useUpdateProviderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProviderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProviderMutation, { data, loading, error }] = useUpdateProviderMutation({
 *   variables: {
 *      provider: // value for 'provider'
 *      providerId: // value for 'providerId'
 *   },
 * });
 */
export function useUpdateProviderMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProviderMutation, UpdateProviderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProviderMutation, UpdateProviderMutationVariables>(UpdateProviderDocument, options);
      }
export type UpdateProviderMutationHookResult = ReturnType<typeof useUpdateProviderMutation>;
export type UpdateProviderMutationResult = Apollo.MutationResult<UpdateProviderMutation>;
export type UpdateProviderMutationOptions = Apollo.BaseMutationOptions<UpdateProviderMutation, UpdateProviderMutationVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($updatedUser: UserInput!, $userId: ID!) {
  updateUser(updatedUser: $updatedUser, userId: $userId) {
    ...User
  }
}
    ${UserFragmentDoc}`;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      updatedUser: // value for 'updatedUser'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const GetBillDocument = gql`
    query GetBill($userId: ID!, $billId: ID!) {
  getBill(userId: $userId, billId: $billId) {
    ...Bill
  }
}
    ${BillFragmentDoc}`;

/**
 * __useGetBillQuery__
 *
 * To run a query within a React component, call `useGetBillQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBillQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBillQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      billId: // value for 'billId'
 *   },
 * });
 */
export function useGetBillQuery(baseOptions: Apollo.QueryHookOptions<GetBillQuery, GetBillQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBillQuery, GetBillQueryVariables>(GetBillDocument, options);
      }
export function useGetBillLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBillQuery, GetBillQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBillQuery, GetBillQueryVariables>(GetBillDocument, options);
        }
export type GetBillQueryHookResult = ReturnType<typeof useGetBillQuery>;
export type GetBillLazyQueryHookResult = ReturnType<typeof useGetBillLazyQuery>;
export type GetBillQueryResult = Apollo.QueryResult<GetBillQuery, GetBillQueryVariables>;
export const GetBillImagesDocument = gql`
    query GetBillImages($filterOptions: BillImagesFilterOptions, $paginationOptions: PaginationOptions) {
  getBillImages(
    filterOptions: $filterOptions
    paginationOptions: $paginationOptions
  ) {
    billImages {
      ...BillImages
    }
    nextToken
  }
}
    ${BillImagesFragmentDoc}`;

/**
 * __useGetBillImagesQuery__
 *
 * To run a query within a React component, call `useGetBillImagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBillImagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBillImagesQuery({
 *   variables: {
 *      filterOptions: // value for 'filterOptions'
 *      paginationOptions: // value for 'paginationOptions'
 *   },
 * });
 */
export function useGetBillImagesQuery(baseOptions?: Apollo.QueryHookOptions<GetBillImagesQuery, GetBillImagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBillImagesQuery, GetBillImagesQueryVariables>(GetBillImagesDocument, options);
      }
export function useGetBillImagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBillImagesQuery, GetBillImagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBillImagesQuery, GetBillImagesQueryVariables>(GetBillImagesDocument, options);
        }
export type GetBillImagesQueryHookResult = ReturnType<typeof useGetBillImagesQuery>;
export type GetBillImagesLazyQueryHookResult = ReturnType<typeof useGetBillImagesLazyQuery>;
export type GetBillImagesQueryResult = Apollo.QueryResult<GetBillImagesQuery, GetBillImagesQueryVariables>;
export const GetBillImagesByUserIdBillIdDocument = gql`
    query GetBillImagesByUserIdBillId($userId: ID!, $billId: ID!, $paginationOptions: PaginationOptions) {
  getBillImagesByUserIdBillId(
    userId: $userId
    billId: $billId
    paginationOptions: $paginationOptions
  ) {
    billImages {
      ...BillImages
    }
  }
}
    ${BillImagesFragmentDoc}`;

/**
 * __useGetBillImagesByUserIdBillIdQuery__
 *
 * To run a query within a React component, call `useGetBillImagesByUserIdBillIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBillImagesByUserIdBillIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBillImagesByUserIdBillIdQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      billId: // value for 'billId'
 *      paginationOptions: // value for 'paginationOptions'
 *   },
 * });
 */
export function useGetBillImagesByUserIdBillIdQuery(baseOptions: Apollo.QueryHookOptions<GetBillImagesByUserIdBillIdQuery, GetBillImagesByUserIdBillIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBillImagesByUserIdBillIdQuery, GetBillImagesByUserIdBillIdQueryVariables>(GetBillImagesByUserIdBillIdDocument, options);
      }
export function useGetBillImagesByUserIdBillIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBillImagesByUserIdBillIdQuery, GetBillImagesByUserIdBillIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBillImagesByUserIdBillIdQuery, GetBillImagesByUserIdBillIdQueryVariables>(GetBillImagesByUserIdBillIdDocument, options);
        }
export type GetBillImagesByUserIdBillIdQueryHookResult = ReturnType<typeof useGetBillImagesByUserIdBillIdQuery>;
export type GetBillImagesByUserIdBillIdLazyQueryHookResult = ReturnType<typeof useGetBillImagesByUserIdBillIdLazyQuery>;
export type GetBillImagesByUserIdBillIdQueryResult = Apollo.QueryResult<GetBillImagesByUserIdBillIdQuery, GetBillImagesByUserIdBillIdQueryVariables>;
export const GetBillsDocument = gql`
    query GetBills($userId: ID!, $filterOptions: BillFilterOptions, $paginationOptions: PaginationOptions) {
  getBills(
    userId: $userId
    filterOptions: $filterOptions
    paginationOptions: $paginationOptions
  ) {
    bills {
      ...Bill
    }
    nextToken
  }
}
    ${BillFragmentDoc}`;

/**
 * __useGetBillsQuery__
 *
 * To run a query within a React component, call `useGetBillsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBillsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBillsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      filterOptions: // value for 'filterOptions'
 *      paginationOptions: // value for 'paginationOptions'
 *   },
 * });
 */
export function useGetBillsQuery(baseOptions: Apollo.QueryHookOptions<GetBillsQuery, GetBillsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBillsQuery, GetBillsQueryVariables>(GetBillsDocument, options);
      }
export function useGetBillsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBillsQuery, GetBillsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBillsQuery, GetBillsQueryVariables>(GetBillsDocument, options);
        }
export type GetBillsQueryHookResult = ReturnType<typeof useGetBillsQuery>;
export type GetBillsLazyQueryHookResult = ReturnType<typeof useGetBillsLazyQuery>;
export type GetBillsQueryResult = Apollo.QueryResult<GetBillsQuery, GetBillsQueryVariables>;
export const GetDocumentDocument = gql`
    query GetDocument($documentId: ID!, $userId: ID!) {
  getDocument(documentId: $documentId, userId: $userId) {
    ...Document
  }
}
    ${DocumentFragmentDoc}`;

/**
 * __useGetDocumentQuery__
 *
 * To run a query within a React component, call `useGetDocumentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocumentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocumentQuery({
 *   variables: {
 *      documentId: // value for 'documentId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetDocumentQuery(baseOptions: Apollo.QueryHookOptions<GetDocumentQuery, GetDocumentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDocumentQuery, GetDocumentQueryVariables>(GetDocumentDocument, options);
      }
export function useGetDocumentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDocumentQuery, GetDocumentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDocumentQuery, GetDocumentQueryVariables>(GetDocumentDocument, options);
        }
export type GetDocumentQueryHookResult = ReturnType<typeof useGetDocumentQuery>;
export type GetDocumentLazyQueryHookResult = ReturnType<typeof useGetDocumentLazyQuery>;
export type GetDocumentQueryResult = Apollo.QueryResult<GetDocumentQuery, GetDocumentQueryVariables>;
export const GetDocumentsDocument = gql`
    query GetDocuments($userId: ID!, $billId: ID!, $paginationOptions: PaginationOptions) {
  getDocuments(
    userId: $userId
    billId: $billId
    paginationOptions: $paginationOptions
  ) {
    documents {
      ...Document
    }
  }
}
    ${DocumentFragmentDoc}`;

/**
 * __useGetDocumentsQuery__
 *
 * To run a query within a React component, call `useGetDocumentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDocumentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDocumentsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      billId: // value for 'billId'
 *      paginationOptions: // value for 'paginationOptions'
 *   },
 * });
 */
export function useGetDocumentsQuery(baseOptions: Apollo.QueryHookOptions<GetDocumentsQuery, GetDocumentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDocumentsQuery, GetDocumentsQueryVariables>(GetDocumentsDocument, options);
      }
export function useGetDocumentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDocumentsQuery, GetDocumentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDocumentsQuery, GetDocumentsQueryVariables>(GetDocumentsDocument, options);
        }
export type GetDocumentsQueryHookResult = ReturnType<typeof useGetDocumentsQuery>;
export type GetDocumentsLazyQueryHookResult = ReturnType<typeof useGetDocumentsLazyQuery>;
export type GetDocumentsQueryResult = Apollo.QueryResult<GetDocumentsQuery, GetDocumentsQueryVariables>;
export const GetProviderDocument = gql`
    query GetProvider($providerId: ID!) {
  getProvider(providerId: $providerId) {
    ...Provider
  }
}
    ${ProviderFragmentDoc}`;

/**
 * __useGetProviderQuery__
 *
 * To run a query within a React component, call `useGetProviderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProviderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProviderQuery({
 *   variables: {
 *      providerId: // value for 'providerId'
 *   },
 * });
 */
export function useGetProviderQuery(baseOptions: Apollo.QueryHookOptions<GetProviderQuery, GetProviderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProviderQuery, GetProviderQueryVariables>(GetProviderDocument, options);
      }
export function useGetProviderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProviderQuery, GetProviderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProviderQuery, GetProviderQueryVariables>(GetProviderDocument, options);
        }
export type GetProviderQueryHookResult = ReturnType<typeof useGetProviderQuery>;
export type GetProviderLazyQueryHookResult = ReturnType<typeof useGetProviderLazyQuery>;
export type GetProviderQueryResult = Apollo.QueryResult<GetProviderQuery, GetProviderQueryVariables>;
export const GetProvidersByZipCodeDocument = gql`
    query GetProvidersByZipCode($zipCode: String!) {
  getProvidersByZipCode(zipCode: $zipCode) {
    providers {
      ...Provider
    }
    nextToken
  }
}
    ${ProviderFragmentDoc}`;

/**
 * __useGetProvidersByZipCodeQuery__
 *
 * To run a query within a React component, call `useGetProvidersByZipCodeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProvidersByZipCodeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProvidersByZipCodeQuery({
 *   variables: {
 *      zipCode: // value for 'zipCode'
 *   },
 * });
 */
export function useGetProvidersByZipCodeQuery(baseOptions: Apollo.QueryHookOptions<GetProvidersByZipCodeQuery, GetProvidersByZipCodeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProvidersByZipCodeQuery, GetProvidersByZipCodeQueryVariables>(GetProvidersByZipCodeDocument, options);
      }
export function useGetProvidersByZipCodeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProvidersByZipCodeQuery, GetProvidersByZipCodeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProvidersByZipCodeQuery, GetProvidersByZipCodeQueryVariables>(GetProvidersByZipCodeDocument, options);
        }
export type GetProvidersByZipCodeQueryHookResult = ReturnType<typeof useGetProvidersByZipCodeQuery>;
export type GetProvidersByZipCodeLazyQueryHookResult = ReturnType<typeof useGetProvidersByZipCodeLazyQuery>;
export type GetProvidersByZipCodeQueryResult = Apollo.QueryResult<GetProvidersByZipCodeQuery, GetProvidersByZipCodeQueryVariables>;
export const GetUnprocessedBillImagesByUserIdDocument = gql`
    query GetUnprocessedBillImagesByUserId($userId: ID!, $paginationOptions: PaginationOptions) {
  getUnprocessedBillImagesByUserId(
    userId: $userId
    paginationOptions: $paginationOptions
  ) {
    billImages {
      ...BillImages
    }
    nextToken
  }
}
    ${BillImagesFragmentDoc}`;

/**
 * __useGetUnprocessedBillImagesByUserIdQuery__
 *
 * To run a query within a React component, call `useGetUnprocessedBillImagesByUserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnprocessedBillImagesByUserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnprocessedBillImagesByUserIdQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      paginationOptions: // value for 'paginationOptions'
 *   },
 * });
 */
export function useGetUnprocessedBillImagesByUserIdQuery(baseOptions: Apollo.QueryHookOptions<GetUnprocessedBillImagesByUserIdQuery, GetUnprocessedBillImagesByUserIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUnprocessedBillImagesByUserIdQuery, GetUnprocessedBillImagesByUserIdQueryVariables>(GetUnprocessedBillImagesByUserIdDocument, options);
      }
export function useGetUnprocessedBillImagesByUserIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUnprocessedBillImagesByUserIdQuery, GetUnprocessedBillImagesByUserIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUnprocessedBillImagesByUserIdQuery, GetUnprocessedBillImagesByUserIdQueryVariables>(GetUnprocessedBillImagesByUserIdDocument, options);
        }
export type GetUnprocessedBillImagesByUserIdQueryHookResult = ReturnType<typeof useGetUnprocessedBillImagesByUserIdQuery>;
export type GetUnprocessedBillImagesByUserIdLazyQueryHookResult = ReturnType<typeof useGetUnprocessedBillImagesByUserIdLazyQuery>;
export type GetUnprocessedBillImagesByUserIdQueryResult = Apollo.QueryResult<GetUnprocessedBillImagesByUserIdQuery, GetUnprocessedBillImagesByUserIdQueryVariables>;
export const GetUserDocument = gql`
    query GetUser($userId: ID!) {
  getUser(userId: $userId) {
    ...User
  }
}
    ${UserFragmentDoc}`;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetUserQuery(baseOptions: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
      }
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const GetUsersDocument = gql`
    query GetUsers($paginationOptions: PaginationOptions) {
  getUsers(paginationOptions: $paginationOptions) {
    users {
      id
      name
      email
    }
    nextToken
  }
}
    `;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *      paginationOptions: // value for 'paginationOptions'
 *   },
 * });
 */
export function useGetUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
      }
export function useGetUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
        }
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>;
export type GetUsersQueryResult = Apollo.QueryResult<GetUsersQuery, GetUsersQueryVariables>;
export const SearchForProvidersDocument = gql`
    query SearchForProviders($zipcode: String!, $query: String!) {
  searchForProviders(zipcode: $zipcode, query: $query) {
    ...Provider
  }
}
    ${ProviderFragmentDoc}`;

/**
 * __useSearchForProvidersQuery__
 *
 * To run a query within a React component, call `useSearchForProvidersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchForProvidersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchForProvidersQuery({
 *   variables: {
 *      zipcode: // value for 'zipcode'
 *      query: // value for 'query'
 *   },
 * });
 */
export function useSearchForProvidersQuery(baseOptions: Apollo.QueryHookOptions<SearchForProvidersQuery, SearchForProvidersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchForProvidersQuery, SearchForProvidersQueryVariables>(SearchForProvidersDocument, options);
      }
export function useSearchForProvidersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchForProvidersQuery, SearchForProvidersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchForProvidersQuery, SearchForProvidersQueryVariables>(SearchForProvidersDocument, options);
        }
export type SearchForProvidersQueryHookResult = ReturnType<typeof useSearchForProvidersQuery>;
export type SearchForProvidersLazyQueryHookResult = ReturnType<typeof useSearchForProvidersLazyQuery>;
export type SearchForProvidersQueryResult = Apollo.QueryResult<SearchForProvidersQuery, SearchForProvidersQueryVariables>;