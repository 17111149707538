import { useForm, FormProvider } from "react-hook-form";
import { TextField, Button } from "@mui/material";
import { DocumentInput } from "../generated/graphql";
import React from "react";
import moment from "moment";
import { Img } from "react-image";

type Image = {
  url: string;
  s3Bucket: string;
  key: string;
};

function DocumentInfoForm({
  saveDocument,
  newDocumentImages,
  loading,
}: {
  saveDocument: (documentInput: DocumentInput) => void;
  newDocumentImages: Image[];
  loading: boolean;
}) {
  const methods = useForm<{ dateSent: string; name: string }>({
    defaultValues: {
      dateSent: moment().format("YYYY-MM-DD"),
      name: "",
    },
  });

  const { register, handleSubmit } = methods;

  const onSubmit = async (formData: { dateSent: string; name: string }) => {
    saveDocument({
      ...formData,
      keys: newDocumentImages.map((image) => image.key),
      s3Bucket: newDocumentImages[0].s3Bucket,
    });
    methods.reset();
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          style={{ margin: "5px" }}
          size="small"
          label="Name of document"
          {...register("name")}
        />
        <TextField
          style={{ margin: "5px" }}
          size="small"
          label="Date Sent"
          {...register("dateSent")}
        />
        <div
          style={{
            display: "flex",
            maxWidth: "100%",
            flexWrap: "wrap",
            marginTop: "10px",
            marginBottom: "10px",
          }}
        >
          {newDocumentImages.map((image) => (
            <Img
              src={image.url}
              key={image.key}
              alt="new document"
              style={{ width: "150px", margin: "3px" }}
            />
          ))}
        </div>
        <Button
          disabled={newDocumentImages.length === 0 || loading}
          style={{ margin: "5px" }}
          variant="contained"
          type="submit"
        >
          {loading && "Saving..."}
          {!loading && "Create Document"}
        </Button>
      </form>
    </FormProvider>
  );
}

export default DocumentInfoForm;
