import { BillImagesFragment } from "../generated/graphql";
import { useEffect, useState } from "react";
import InspectImageList from "./InspectImageList";

type Image = {
  url: string;
  s3Bucket: string;
  key: string;
};

const BillImagesControls = ({
  billImages,
  addImageToDocument,
  newDocumentImages,
}: {
  billImages: BillImagesFragment;
  display: boolean;
  addImageToDocument: (image: Image) => void;
  newDocumentImages: Image[];
}) => {
  const [processedIndexes, setProcessedIndexes] = useState<number[]>([]);

  useEffect(() => {
    // TODO: this is kind of a smelly way of doing all this,
    //  but is probably the best way until we only render the correct BillImageComponent
    const processedIndexList: number[] =
      billImages.processedKeys?.map((key) => billImages.keys.indexOf(key)) ??
      [];
    const toAddList = [];
    for (const item of processedIndexList) {
      if (!processedIndexes.includes(item)) {
        toAddList.push(item);
      }
    }
    setProcessedIndexes([...processedIndexes, ...toAddList]);
  }, [billImages]);

  const addImageAtIndex = (index: number) => {
    addImageToDocument({
      url: billImages.imageUrls[index] as string,
      key: billImages.keys[index] as string,
      s3Bucket: billImages.s3Bucket,
    });
    if (!processedIndexes.includes(index)) {
      setProcessedIndexes([...processedIndexes, index]);
    }
  };

  return (
    <InspectImageList
      images={billImages.imageUrls as string[]}
      addImageAtIndex={addImageAtIndex}
      processedImageIndexes={processedIndexes}
    />
  );
};

export default BillImagesControls;
