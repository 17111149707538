import { Button, TextField, MenuItem, Alert, Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import moment from "moment";
import ReactHookFormSelect from "./ReactHookFormSelect";

function PaymentsInput({ amountAfterIns }: { amountAfterIns: number }) {
  const [amountDue, setAmountDue] = useState<number>(0.0);
  const { register, control, watch, getValues, setValue } = useFormContext();

  const {
    fields: paymentsFields,
    append: paymentsAppend,
    remove: paymentsRemove,
  } = useFieldArray({
    control,
    name: "payments",
  });

  const watchFieldArray = watch("payments");
  const controlledFields = paymentsFields.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray[index],
    };
  });

  useEffect(() => {
    let paid = 0.0;
    controlledFields.forEach((payment: any) => {
      paid += parseFloat(payment.amount || 0);
    });
    setAmountDue(amountAfterIns - paid);
    setValue("paidByUser", paid);
  }, [controlledFields, amountAfterIns, setValue]);

  const dateString = moment().format("YYYY-MM-DD");

  return (
    <div style={{ marginTop: "10px" }}>
      <div className="input-list">
        <h5>Payments</h5>
        {controlledFields.map((payment, index) => (
          <div key={payment.id} className="input-list-row-item">
            <label htmlFor={`payment.${index}.id`}>Payment</label>
            <TextField
              size="small"
              label="Payment Date"
              {...register(`payments.${index}.date`)}
            />
            <TextField
              label="Paid Amount"
              // @ts-ignore
              control={control}
              size="small"
              placeholder="Billed Amount"
              {...register(`payments.${index}.amount`)}
            />
            <ReactHookFormSelect
              variant="outlined"
              size="small"
              {...register(`payments.${index}.type`)}
              ref={null}
            >
              <MenuItem value={"EXTERNAL"}>EXTERNAL</MenuItem>
              <MenuItem value={"COPAY"}>COPAY</MenuItem>
            </ReactHookFormSelect>
            <Button onClick={() => paymentsRemove(index)} variant="contained">
              X
            </Button>
          </div>
        ))}
        <Button
          onClick={() => {
            paymentsAppend({
              amount: 0,
              type: "EXTERNAL",
              date:
                paymentsFields.length > 0
                  ? getValues(`payments.${paymentsFields.length - 1}.date`)
                  : dateString,
            });
          }}
          className="add-button"
          variant="contained"
        >
          +
        </Button>
        <TextField
          style={{ marginTop: "20px" }}
          size="small"
          label="Paid By User"
          {...register("paidByUser")}
        />
        <Alert className="alert" severity="info" style={{ marginTop: "10px" }}>
          Only fill in the paid by user if the individual payments aren't
          available to enter
        </Alert>
      </div>
      <Container style={{ fontWeight: "bold" }}>
        Amount Due after insurance and payments: ${amountDue}
      </Container>
    </div>
  );
}

export default PaymentsInput;
