import React, { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import {
  useGetUnprocessedBillImagesByUserIdQuery,
  useGetDocumentsQuery,
  useCreateDocumentMutation,
  useUpdateBillImagesMutation,
  DocumentInput,
  BillImages,
  useGetUserQuery,
} from "../generated/graphql";
import BillImagesComponent from "../components/BillImagesComponent";
import { Container, Stack, Button } from "@mui/material";
import DocumentDisplay from "../components/DocumentDisplay";
import BillSubmissions from "../components/BillSubmissions";

type Image = {
  url: string;
  s3Bucket: string;
  key: string;
};

function BillImagesToDocuments() {
  const [newDocumentImages, setNewDocumentImages] = useState<Image[]>([]);
  const [selectedSubmission, setSelectedSubmission] = useState<number>(0);

  const userId = useParams().userId || "test";
  const documentId = useParams().documentId || "test";

  const userResponse = useGetUserQuery({
    variables: {
      userId,
    },
  });

  const { data, loading } = useGetUnprocessedBillImagesByUserIdQuery({
    variables: {
      userId,
      paginationOptions: {
        limit: 10,
      },
    },
  });

  const billImages = useMemo(
    () =>
      data?.getUnprocessedBillImagesByUserId?.billImages?.filter(
        (billImages) => billImages?.pending === false
      ),
    [data]
  );

  useEffect(() => {
    setSelectedSubmission(
      billImages?.findIndex((image) => image.id === documentId) || 0
    );
  }, [data, billImages, documentId]);

  const {
    data: docData,
    loading: loadingDocs,
    refetch,
  } = useGetDocumentsQuery({
    variables: {
      userId: userId,
      billId: "null",
      paginationOptions: {
        limit: 50,
      },
    },
  });

  const [createDocumentMutation, { loading: loadingCreateDocument }] =
    useCreateDocumentMutation();
  const [
    updateBillImagesMutation,
    { loading: loadingBillImages, error: billImagesError },
  ] = useUpdateBillImagesMutation();

  const saveDocument = (document: DocumentInput) => {
    if (!billImages) return null;
    const processedKeys = new Set([
      ...(billImages[selectedSubmission].processedKeys || []),
      ...document.keys,
    ]);

    const createDocumentPromise = createDocumentMutation({
      variables: {
        document: {
          ...document,
          billImagesId: billImages[selectedSubmission].id,
        },
        userId,
      },
    });

    createDocumentPromise
      .then(() =>
        updateBillImagesMutation({
          variables: {
            userId,
            billImagesId: billImages[selectedSubmission].id,
            billImagesInput: {
              processedKeys: Array.from(processedKeys) as string[],
            },
          },
        })
      )
      .then(() => {
        refetch();
        setNewDocumentImages([]);
      });
  };

  if (loading || loadingDocs || !docData) {
    return <div>Loading...</div>;
  }

  if (billImages?.length === 0 || !billImages) {
    return <div>All bill images have been processed</div>;
  }

  return (
    <Container>
      <Stack direction={"row"} margin={2} style={{ width: "98%" }}>
        <Container style={{ minWidth: "50%" }}>
          {billImages[selectedSubmission] && (
            <BillImagesComponent
              billImages={billImages[selectedSubmission]}
              display={true}
              addImageToDocument={(image: Image) => {
                setNewDocumentImages([...newDocumentImages, image]);
              }}
              newDocumentImages={newDocumentImages}
            />
          )}
          <div>
            <h3>
              Processing submission {selectedSubmission + 1} out of{" "}
              {billImages.length}
            </h3>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              {selectedSubmission !== 0 && (
                <Button
                  variant="contained"
                  size="large"
                  onClick={() => {
                    window.location.href = `/users/${userId}/submissions/${
                      billImages[selectedSubmission - 1].id
                    }`;
                  }}
                >
                  Previous Submission
                </Button>
              )}
              {selectedSubmission + 1 < billImages.length && (
                <Button
                  variant="contained"
                  size="large"
                  onClick={() => {
                    window.location.href = `/users/${userId}/submissions/${
                      billImages[selectedSubmission + 1].id
                    }`;
                  }}
                >
                  Next Submission
                </Button>
              )}
            </div>
          </div>
        </Container>
        <DocumentDisplay
          newDocumentImages={newDocumentImages}
          documents={docData?.getDocuments?.documents || []}
          saveDocument={saveDocument}
          loading={loadingBillImages || loadingCreateDocument}
        />
      </Stack>
      <h1 style={{ marginBottom: "40px" }}>
        All Unprocessed Bill Submissions for {userResponse.data?.getUser?.name}
      </h1>
      <BillSubmissions data={billImages as BillImages[]} />
    </Container>
  );
}

export default BillImagesToDocuments;
