import { Box, Button } from "@mui/material";
import Zoom from "react-medium-image-zoom";
import { Img } from "react-image";
import { useEffect, useState } from "react";

const style = {};

const InspectImageList = ({
  images,
  addImageAtIndex,
  processedImageIndexes = [],
}: {
  images: string[];
  addImageAtIndex?: (index: number) => void;
  processedImageIndexes?: number[];
}) => {
  const [bigImage, setBigImage] = useState(0);
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(false);

  useEffect(() => {
    if (!processedImageIndexes.includes(bigImage)) {
      return;
    }
    for (let i = 0; i < images.length; i++) {
      if (!processedImageIndexes.includes(i)) {
        setBigImage(i);
        return;
      }
    }
    setBigImage(-1);
  }, [processedImageIndexes, bigImage, images.length]);

  return (
    <Box sx={style}>
      {bigImage === -1 && <div>All images have been assigned to documents</div>}
      {bigImage !== -1 && (
        <div>
          <Button onClick={() => setRotation(rotation + 90)}>Rotate</Button>
          <Button onClick={() => setZoom(!zoom)}>Enlarge</Button>
        </div>
      )}
      <Box
        maxHeight="90%"
        display="grid"
        overflow="scroll"
        gridTemplateColumns="repeat(3, 1fr)"
      >
        <Box
          style={{
            gridColumn: `1 / span ${zoom ? "3" : "2"}`,
            gridRow: "1 / span 100",
            margin: "0 1rem 0 0",
            aspectRatio: "1/1",
          }}
        >
          {images.length > 0 && bigImage > -1 && (
            <Zoom>
              <Img
                onClick={() => setRotation(0)}
                alt="bill scan submission"
                src={images[bigImage] ?? ""}
                style={{ width: "100%", transform: `rotate(${rotation}deg)` }}
              />
              {addImageAtIndex !== undefined && (
                <Button
                  onClick={() => {
                    if (images[bigImage] !== null) {
                      addImageAtIndex(bigImage);
                    }
                    if (processedImageIndexes.length < images.length) {
                      for (let i = 0; i < images.length; i++) {
                        if (!processedImageIndexes.includes(i)) {
                          setBigImage(i);
                          return;
                        }
                      }
                    }
                    setBigImage(-1);
                  }}
                  style={{
                    margin: "5px",
                    display: "block",
                  }}
                  variant="contained"
                >
                  Add
                </Button>
              )}
            </Zoom>
          )}
        </Box>

        {images.map((imageUrl, index) => {
          return (
            <Box
              key={imageUrl}
              style={{
                filter: processedImageIndexes.includes(index)
                  ? "brightness(40%)"
                  : "none",
                gridColumn: "auto",
                gridRow: "auto",
                margin: "2px",
              }}
            >
              <img
                onClick={() => {
                  setBigImage(index);
                }}
                alt="bill scan submission"
                src={imageUrl}
                style={{ width: "100%" }}
              />
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

export default InspectImageList;
