import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { DocumentFragment } from "../generated/graphql";

const DocumentsTable = ({ data, userId }: { data: DocumentFragment[], userId: string }) => {
  console.log(data);
  return (
    <TableContainer component={Paper}>
      <Table
        sx={{
          minWidth: 650,
          "& .MuiTableRow-root:hover": {
            backgroundColor: "primary.light",
          },
        }}
        aria-label="simple table"
      >
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Date Sent</TableCell>
            <TableCell align="right">ID</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow
              key={row.id}
              sx={{ "&:last-child td, &:last-child th": { border: 0 }, cursor: "pointer" }}
              onClick={()=> window.location.href = `/users/${userId}/documents/${row.id}`}
            >
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="left">{row.dateSent}</TableCell>
              <TableCell align="right">{row.id}</TableCell>
            </TableRow>
          ))}
          </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DocumentsTable;
