import { BillImagesFragment } from "../generated/graphql";
import { useState } from "react";
import { Alert, Box, Button, Container, List, ListItem } from "@mui/material";
import { CheckBox as CheckboxIcon } from "@mui/icons-material";
import BillImagesControls from "./BillImagesControls";

type Image = {
  url: string;
  s3Bucket: string;
  key: string;
};

const BillImagesComponent = ({
  billImages,
  display,
  addImageToDocument,
  newDocumentImages,
}: {
  billImages: BillImagesFragment;
  display: boolean;
  addImageToDocument: (image: Image) => void;
  newDocumentImages: Image[];
}) => {
  const [isEOB, setIsEOB] = useState<boolean>(billImages.isEOB ?? false);

  return (
    <Container
      className="scrollable-image-display"
      style={{
        minWidth: "50%",
        display: display ? "flex" : "none",
      }}
      key={billImages.id}
    >
      <List>
        <ListItem key="title">
          <Box
            width="100%"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box display="flex" gap="1rem" alignItems="end">
              <h4 style={{ margin: 0 }}>Submission:</h4>
              <p style={{ margin: 0 }}>
                {billImages?.id?.slice(0, 4)}...
                {billImages?.id?.slice(-5, billImages.id.length)}
              </p>
            </Box>
          </Box>
        </ListItem>
        <ListItem>
          <h5>Images</h5>
        </ListItem>
        <BillImagesControls
          billImages={billImages}
          display={display}
          addImageToDocument={addImageToDocument}
          newDocumentImages={newDocumentImages}
        />
        {/*<ListItem key="processed">*/}
        {/*  <Box display="flex">*/}
        {/*    <Alert severity="warning">*/}
        {/*      Do not mark as processed until all documents have been created for*/}
        {/*      this image submission*/}
        {/*    </Alert>*/}
        {/*    {billImages.isProcessed ? (*/}
        {/*      <>*/}
        {/*        <CheckboxIcon />*/}
        {/*        "️Processed"*/}
        {/*      </>*/}
        {/*    ) : (*/}
        {/*      <Button*/}
        {/*        variant="contained"*/}
        {/*        type="button"*/}
        {/*        color="error"*/}
        {/*        onClick={() => {*/}
        {/*          processBillImages({ isEOB, isProcessed: true });*/}
        {/*        }}*/}
        {/*      >*/}
        {/*        Set As Processed*/}
        {/*      </Button>*/}
        {/*    )}*/}
        {/*  </Box>*/}
        {/*</ListItem>*/}
      </List>
    </Container>
  );
};

export default BillImagesComponent;
