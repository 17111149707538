import { Provider } from "../../generated/graphql";
import { Button, Container } from "@mui/material";
import "./ProviderCard.css";

function ProviderCard({
  provider,
  onSelect,
  onEdit,
  onAddToOopda,
  searchResults,
}: {
  provider: Provider;
  onSelect?: Function;
  onEdit?: Function;
  onAddToOopda?: Function;
  searchResults?: boolean;
}) {
  return (
    <Container
      className="provider-card"
      style={{ marginBottom: "5px", padding: "10px" }}
    >
      <div>Name: {provider.name}</div>
      <div>Phone: {provider.phoneNumber}</div>
      <div>Website: {provider.billingWebAddress}</div>
      <div>Availability: {provider.availability}</div>
      <div>
        Address:
        {provider.address.street},{" "}
        {provider.address.streetAdditional
          ? provider.address.streetAdditional + ", "
          : ""}
        {provider.address.city}, {provider.address.state}{" "}
        {provider.address.zipCode}
      </div>
      {searchResults && (
        <div>
          {provider.saved && onSelect && (
            <Button
              size="small"
              variant="contained"
              onClick={() => onSelect(provider.id)}
            >
              Select
            </Button>
          )}
          {onEdit && (
            <Button
              size="small"
              variant="contained"
              onClick={() => onEdit(provider.id)}
            >
              Edit
            </Button>
          )}
          {!provider.saved && onAddToOopda && (
            <Button
              size="small"
              variant="contained"
              onClick={() => onAddToOopda()}
            >
              Add to Oopda
            </Button>
          )}
          <div
            style={{
              display: "inline-block",
              color: "gray",
              margin: "10px",
            }}
          >
            {provider.saved ? "Oopda Provider" : "Marketplace API Provider"}
          </div>
        </div>
      )}
    </Container>
  );
}

export default ProviderCard;
