import React, { useState, useEffect } from "react";
import {
  BillImagesFragment,
  DocumentFragment,
  useGetDocumentsQuery,
  useGetUnprocessedBillImagesByUserIdQuery,
  useGetUserQuery,
} from "../generated/graphql";
import { useParams } from "react-router-dom";
import { Box } from "@mui/material";
import DocumentsTable from "./DocumentsTable";

const UserDocuments = () => {
  const userId = useParams().userId || "test";

  const documentsResponse = useGetDocumentsQuery({
    variables: {
      userId: userId,
      billId: "null",
    },
  });

  const documents: DocumentFragment[] =
    documentsResponse.data?.getDocuments?.documents || [];

  const userResponse = useGetUserQuery({
    variables: {
      userId,
    },
  });

  return (
    <Box sx={{ m: 5 }}>
      <h3 style={{marginBottom: "20px"}} >Unprocessed Documents for {userResponse.data?.getUser?.name}</h3>
      <DocumentsTable data={documents} userId={userId} />
    </Box>
  );
};

export default UserDocuments;
