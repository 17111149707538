import { Button, TextField, MenuItem, Container } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import BillingCodeInput from "./BillingCodeInput";
import moment from "moment";
import ReactHookFormSelect from "./ReactHookFormSelect";

function InsuranceAlterationsInput({
  billingCodes,
  amountOwedBeforeAlts,
  sendAmountAfterIns,
}: {
  billingCodes: string[];
  amountOwedBeforeAlts: number;
  sendAmountAfterIns: (amount: number) => void;
}) {
  const [amountAfterIns, setAmountAfterIns] = useState(0.0);
  const { control, register, getValues, watch } = useFormContext();

  const dateString = moment().format("YYYY-MM-DD");
  const { fields, append, remove } = useFieldArray({
    control,
    name: "insuranceAlterations",
  });

  const watchFieldArray = watch("insuranceAlterations");
  const controlledFields = fields.map((field, index) => {
    return {
      ...field,
      ...watchFieldArray[index],
    };
  });

  useEffect(() => {
    let insPaid = 0.0;
    controlledFields.forEach((insuranceAlteration: any) => {
      insPaid += parseFloat(insuranceAlteration.amount || 0);
    });
    setAmountAfterIns(amountOwedBeforeAlts - insPaid);
    sendAmountAfterIns(amountOwedBeforeAlts - insPaid);
  }, [
    amountOwedBeforeAlts,
    controlledFields,
    amountAfterIns,
    sendAmountAfterIns,
  ]);

  return (
    <div className="input-list" style={{ marginTop: "10px" }}>
      <h5>Insurance Additions</h5>
      {controlledFields.map((insuranceAlteration, index) => (
        <div key={insuranceAlteration.id} className="input-list-row-item">
          <BillingCodeInput index={index} optionsList={billingCodes} />
          <TextField
            size="small"
            label="Amount"
            defaultValue={0.0}
            {...register(`insuranceAlterations.${index}.amount`)}
          />
          <TextField
            size="small"
            label="Date"
            defaultValue={dateString}
            {...register(`insuranceAlterations.${index}.date`)}
          />
          <TextField
            size="small"
            label="Reason"
            defaultValue={""}
            {...register(`insuranceAlterations.${index}.reason`)}
          />
          <ReactHookFormSelect
            variant="outlined"
            size="small"
            {...register(`insuranceAlterations.${index}.type`)}
            ref={null}
          >
            <MenuItem value={"PAYMENT"}>PAYMENT</MenuItem>
            <MenuItem value={"ADJUSTMENT"}>ADJUSTMENT</MenuItem>
          </ReactHookFormSelect>
          <Button onClick={() => remove(index)} variant="contained">
            X
          </Button>
        </div>
      ))}
      <Button
        onClick={() => {
          append({
            billingCodes: [],
            type: "PAYMENT",
            date:
              controlledFields.length > 0
                ? getValues(
                    `insuranceAlterations.${controlledFields.length - 1}.date`
                  )
                : dateString,
          });
        }}
        variant="contained"
      >
        +
      </Button>
      <Container style={{ fontWeight: "bold", marginTop: "10px" }}>
        Amount Due after Insurance: ${amountAfterIns}
      </Container>
    </div>
  );
}

export default InsuranceAlterationsInput;
