import React from "react";
import { DocumentFragment } from "../generated/graphql";
import DocumentInfoForm from "./DocumentInfoForm";
import { Link } from "react-router-dom";
import { Container } from "@mui/material";
import { Img } from "react-image";
import { useParams } from "react-router-dom";

type Image = {
  url: string;
  s3Bucket: string;
  key: string;
};

const DocumentDisplay = ({
  documents,
  newDocumentImages,
  saveDocument,
  loading,
}: {
  documents: DocumentFragment[];
  newDocumentImages: Image[];
  saveDocument: (document: any) => void;
  loading: boolean;
}) => {
  const userId = useParams().userId || "test";
  return (
    <Container style={{ border: "solid lightGray 3px", borderRadius: "8px" }}>
      <div>
        <h3 style={{ marginTop: "10px", marginBottom: "10px" }}>
          New Document:
        </h3>
        <DocumentInfoForm
          loading={loading}
          saveDocument={saveDocument}
          newDocumentImages={newDocumentImages}
        />
      </div>
      <div>
        <h3 style={{ marginTop: "20px", marginBottom: "10px" }}>
          Existing Documents:
        </h3>
        <div>
          {documents.map((document) => {
            return (
              <div key={document.id} style={{ margin: "10px" }}>
                <h4>Name: {document.name}</h4>
                <h4>Date Sent: {document.dateSent}</h4>
                <h4>Images:</h4>
                <div style={{ display: "flex" }}>
                  {document.imageUrls?.map((imageUrl) => {
                    return (
                      <Img
                        src={imageUrl}
                        key={imageUrl}
                        alt="new document"
                        style={{ width: "150px", margin: "3px" }}
                      />
                    );
                  })}
                </div>
                {!document.billId && (
                  <Link to={`/users/${userId}/documents/${document.id}`}>
                    Associate Document With Bill
                  </Link>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </Container>
  );
};

export default DocumentDisplay;
