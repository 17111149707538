import { Box, Button } from "@mui/material";
import {
  GetDocumentsDocument,
  useGetBillImagesByUserIdBillIdQuery,
  useGetDocumentsQuery,
  useUpdateBillImagesMutation,
  useUpdateDocumentMutation,
} from "../../generated/graphql";
import BillImagesControls from "../BillImagesControls";
import { useCallback } from "react";
const BillImageOptions = ({
  userId,
  billId,
}: {
  userId: string;
  billId: string;
}) => {
  const { data: doc } = useGetDocumentsQuery({
    variables: {
      userId: userId,
      billId: billId,
      paginationOptions: {},
    },
  });
  const { data } = useGetBillImagesByUserIdBillIdQuery({
    variables: {
      userId: userId,
      billId: billId,
      paginationOptions: {},
    },
  });

  const billImage = data?.getBillImagesByUserIdBillId?.billImages?.[0];
  const document = doc?.getDocuments.documents?.[0];

  const [updateBillImagesMutation] = useUpdateBillImagesMutation();
  const [updateDocumentMutation] = useUpdateDocumentMutation();

  const processBillImages = useCallback(async () => {
    if (!billImage || !document) return null;
    await updateDocumentMutation({
      variables: {
        userId: userId,
        documentId: document?.id,
        document: {
          billId: billId,
          keys: [],
          s3Bucket: billImage.s3Bucket,
        },
      },
      refetchQueries: [GetDocumentsDocument],
    });

    await updateBillImagesMutation({
      variables: {
        billImagesId: billImage?.id || "",
        userId: userId,
        billImagesInput: {
          isProcessed: true,
          billId: billId,
          processedKeys: [],
        },
      },
    });
  }, [updateBillImagesMutation, billImage, userId, billId]);

  if (!billImage) return <h1>loading</h1>;
  return (
    <div>
      <p>Images associated with bill</p>
      <Box display="flex" flexWrap="wrap" maxWidth="sm">
        {document?.imageUrls?.map((img, index) => (
          <img key={img} style={{ width: "100%" }} src={img} />
        ))}
      </Box>
      <p>Images from original document</p>
      <BillImagesControls
        display={true}
        billImages={billImage}
        addImageToDocument={() => {}}
        newDocumentImages={[]}
      />
      <Box display="flex" justifyContent="right">
        <Button onClick={processBillImages} variant="contained" color="error">
          Update document with selected images
        </Button>
      </Box>
    </div>
  );
};

export default BillImageOptions;
