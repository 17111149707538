import { TextField, Button } from "@mui/material";
import {
  Provider,
  useSearchForProvidersLazyQuery,
} from "../../generated/graphql";
import React, { useState } from "react";
import { useFormContext } from "react-hook-form";
import ProviderCard from "../cards/ProviderCard";
import UpdateProviderForm from "./UpdateProviderForm";

export type ProviderTabState = {
  selectedProvider: Provider | null;
  listOfProviders: Provider[];
  zipcode: string;
  providerName: string;
  providerId?: string | null;
};

function ProviderInput({
  setProviderState,
  tabState,
}: {
  setProviderState: Function;
  tabState: ProviderTabState;
}) {
  const { zipcode, providerName, selectedProvider, listOfProviders } = tabState;
  const [searchForProviders, { loading, error }] =
    useSearchForProvidersLazyQuery();

  const [showCreateModal, setShowCreateModal] = useState(false);
  const [editProvider, setEditProvider] = useState(null);

  const { register, setValue } = useFormContext();

  const searchForProvider = async () => {
    if (!zipcode) {
      return;
    }

    const response = await searchForProviders({
      variables: {
        zipcode: zipcode,
        query: providerName,
      },
    });

    setProviderState({
      ...tabState,
      listOfProviders: response.data?.searchForProviders || [],
    });
  };

  const SelectProvider = (provider: Provider | null) => {
    setProviderState({
      ...tabState,
      selectedProvider: provider,
      listOfProviders: [],
    });
    setValue("providerId", provider && provider.id ? provider.id : null);
  };

  const CancelProvider = () => {
    setProviderState({ ...tabState, selectedProvider: null });
    setValue("providerId", null);
  };

  return (
    <div>
      <h5>Provider</h5>
      <TextField
        style={{ marginTop: "10px" }}
        size="small"
        label="Individual Provider Name"
        {...register("individualProviderName")}
      />
      {selectedProvider && (
        <div style={{ marginTop: "10px" }}>
          <ProviderCard provider={selectedProvider} />
          <Button onClick={CancelProvider} variant="contained">
            Clear
          </Button>
        </div>
      )}
      {!selectedProvider && (
        <div className="provider-form" style={{ marginTop: "10px" }}>
          <div
            className="provider-search input-list-row-item"
            style={{ padding: "5px", borderRadius: 6 }}
          >
            <div>Search For Provider</div>
            <TextField
              size="small"
              label="ZipCode"
              value={zipcode}
              type="number"
              onChange={(event) =>
                setProviderState({ ...tabState, zipcode: event.target.value })
              }
            />
            <TextField
              size="small"
              label="Provider Name"
              value={providerName}
              onChange={(event) =>
                setProviderState({
                  ...tabState,
                  providerName: event.target.value,
                })
              }
            />
            <Button
              disabled={loading}
              onClick={searchForProvider}
              variant="contained"
            >
              Search
            </Button>
          </div>
          {listOfProviders && (
            <div className="existing-providers" style={{ marginTop: "10px" }}>
              <h3>Existing Providers</h3>
              {loading && <div>Searching...</div>}
              {error && <div>Error: {error.message}</div>}
              {listOfProviders.length === 0 && <div>None</div>}
              {listOfProviders.map((provider: any) => {
                return (
                  <ProviderCard
                    provider={provider}
                    onSelect={() => SelectProvider(provider)}
                    onAddToOopda={() => {
                      setShowCreateModal(true);
                      window.scrollTo(0, 0);
                      setEditProvider(provider);
                    }}
                    searchResults={true}
                    key={provider.id}
                  />
                );
              })}
            </div>
          )}
          {!showCreateModal && (
            <Button
              style={{ marginTop: "10px" }}
              type="button"
              size="small"
              variant="contained"
              onClick={() => setShowCreateModal(true)}
            >
              Create New Provider
            </Button>
          )}
          {showCreateModal && (
            <UpdateProviderForm
              existing={editProvider}
              afterSubmit={(provider: any) => {
                setShowCreateModal(false);
                if (provider && provider.id) {
                  SelectProvider(provider);
                }
              }}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default ProviderInput;
